import { AUTH_USER, SEARCH_TEXT, USER_DETAILS } from "../constants";

export default function ActionReducer(state = [], action) {
  switch (action.type) {
    case AUTH_USER: {
      return { authdata: action.data };
    }
    case USER_DETAILS: {
      return { userDetails: action.data }
    }
    case SEARCH_TEXT: {
      return { searchText: action?.data }
    }
    default: {
      return { state: [] };
    }
  }
}