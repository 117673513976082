import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import moment from 'moment';
import { IntlContext } from 'src/App';
import { createStyles, makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import axiosInstance from 'src/helpers/context/axiosInstance';
import { URLS } from 'src/helpers/constants/url';
import PreLoader from 'src/component/Loader';

const useStyles = makeStyles((theme) =>
  createStyles({
    root_b: {
      padding: '100px 0 200px',
      '& .result_text': {
        fontSize: '16px',
        color: '#405CFB',
        textTransform: 'uppercase',
        lineHeight: '16px',
        paddingBottom: '18px',
      },
      '& .text_align': {
        textAlign: 'left',
        alignItems: 'center',
      },
      '& .test_name': {
        fontSize: '26px',
        lineHeight: '30px',
        fontWeight: '600',
        paddingBottom: '12px',
      },
      '& .user_name': {
        fontSize: '16px',
        lineHeight: '20px',
        color: '#6A6A6A',
      },
      '& .result_box': {
        '& .MuiTypography-root': {
          fontSize: '18px',
          '@media (max-width:899px)': {
            fontSize: '16px',
          },
        },
      },
      '& .test_feature': {
        lineHeight: '19px',
        color: '#373737',
      },
      '& .feedback_block': {
        backgroundColor: '#F2F2F2',
        padding: '18px 18px 18px 22px',
        borderRadius: '8px',
        marginTop: '36px',
        position: 'relative',
        maxWidth: '60%',
        '@media (max-width:899px)': {
          maxWidth: '100%',
        },
        '&:before': {
          content: "''",
          position: 'absolute',
          display: 'block',
          background: '#268855',
          width: '8px',
          height: '100%',
          top: '0',
          left: '0',
          borderRadius: '8px 0 0 8px',
        },
        '& .feedback_title': {
          fontSize: '16px',
          fontWeight: '700',
          lineHeight: '20px',
          color: '#373737',
        },
      },
    },
  })
);

const TestResult = () => {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [loader, setLoader] = useState(false);
  const [testResult, setTestResult] = useState({});
  const [testData, setTestData] = useState('');
  const [userName, setUserName] = useState('');
  const location = useLocation();

  var hashes = location?.search?.split('?')[1];
  var hash = hashes?.split('&');
  var testId = hash?.[0]?.split('=')[1];
  var orderId = hash?.[1]?.split('=')[1];
  var userId = hash?.[2]?.split('=')[1];
  var testResultId = hash?.[3]?.split('=')[1];

  const getResultData = async () => {
    let payload = {
      productForm: testId && testId,
      orderId: orderId && orderId,
      userId: userId && userId,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS?.getLowerCategories,
        payload
      );
      if (status === 200) {
        setLoader(false);
        data?.data?.map((item) => {
          setTestData(item?.name);
        });
        data?.data?.map((item) => {
          item?.testScore?.filter((test) => {
            if (test?._id === testResultId) {
              setTestResult(test);
            }
          });
        });
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const getUser = async () => {
    let payload = {
      _id: userId && userId,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS?.changeUserProfile,
        payload
      );
      if (status === 200) {
        setLoader(false);
        setUserName(data?.data?.name);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getResultData();
    getUser();
  }, []);

  

  var dayName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const IsoStartDate = new Date(testResult?.startDate);
  const sDay = dayName[IsoStartDate?.getDay()];
  const sDate = IsoStartDate?.getDate();
  const sMonthName = month[IsoStartDate?.getMonth()];
  const sYear = IsoStartDate?.getFullYear().toString().slice(-2);
  const sHour = IsoStartDate?.getHours();
  const sMinitue = String(IsoStartDate?.getMinutes()).padStart(2, '0');
  const testStartDate =
    sDay +
    ' ' +
    sDate +
    ' ' +
    sMonthName +
    ' ' +
    "'" +
    sYear +
    ' ' +
    sHour +
    ':' +
    sMinitue;

  const IsoEndDate = new Date(testResult?.testEndDate);
  const eDay = dayName[IsoEndDate?.getDay()];
  const eDate = IsoEndDate?.getDate();
  const eMonthName = month[IsoEndDate?.getMonth()];
  const eYear = IsoEndDate?.getFullYear().toString().slice(-2);
  const eHour = IsoEndDate?.getHours();
  const eMinitue = String(IsoEndDate?.getMinutes()).padStart(2, '0');
  const testEndDate =
    eDay +
    ' ' +
    eDate +
    ' ' +
    eMonthName +
    ' ' +
    "'" +
    eYear +
    ' ' +
    eHour +
    ':' +
    eMinitue;

  return (
    <>
      {loader && <PreLoader />}
      <Box className="main_container">
        <Box className={classes.root_b}>
          <Typography className="result_text">
            {gothe_mile?.TestResults?.results}
          </Typography>
          <Typography className="test_name">{testData}</Typography>
          <Typography className="user_name">{userName}</Typography>
          <Box className="result_box" sx={{ pt: '27px' }}>
            <Grid container className="text_align">
              <Grid item xs={4} sx={{ pb: '6px' }}>
                <Typography className="test_feature">
                  {gothe_mile?.TestResults?.points}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ pb: '6px' }}>
                <Typography sx={{ fontWeight: '700' }}>
                  {testResult?.testScore + '/' + testResult?.testScoreAvailable}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="text_align">
              <Grid item xs={4} sx={{ p: '6px 0' }}>
                <Typography className="test_feature">
                  {gothe_mile?.TestResults?.percentage}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ p: '6px 0' }}>
                <Typography sx={{ fontWeight: '700' }}>
                  {testResult?.testPercentage + '%'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="text_align">
              <Grid item xs={4} sx={{ p: '6px 0' }}>
                <Typography className="test_feature">
                  {gothe_mile?.TestResults?.duration}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ p: '6px 0' }}>
                <Typography sx={{ fontWeight: '700' }}>
                  {testResult?.testDuration}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="text_align">
              <Grid item xs={4} sx={{ p: '6px 0' }}>
                <Typography className="test_feature">
                  {gothe_mile?.TestResults?.dateStarted}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ p: '6px 0' }}>
                <Typography sx={{ fontWeight: '700' }}>
                  {testStartDate}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="text_align">
              <Grid item xs={4} sx={{ pt: '6px' }}>
                <Typography className="test_feature">
                  {gothe_mile?.TestResults?.dateFinished}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ pt: '6px' }}>
                <Typography sx={{ fontWeight: '700' }}>
                  {testEndDate}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className="feedback_block">
            <Box>
              <Typography className="feedback_title">
                {gothe_mile?.TestResults?.feedback}
              </Typography>
              <Typography
                className="feedback_title"
                sx={{ mt: '6px', fontWeight: '400!important' }}
              >
                {testResult?.feedback}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default TestResult;
