import React, { useContext, useState } from "react";
import { Box, Typography, Button, FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { InputTextField } from "../UI/textfield";
import { IntlContext } from "src/App";
import { createStyles, makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
// import MessageBox from "../UI/MessageBox";
import PreLoader from "src/component/Loader";
import { EventEmitter } from "src/helpers/context/EventEmitter";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const useStyles = makeStyles((theme) => createStyles({
    root: {
        position: "relative",
        background: "#F7F7F7",
        borderRadius: "16px",
        padding: "33px 46px 44px 47px",
        "@media (max-width:1199px)": {
            padding: "30px 45px 40px",
        },
        "@media (max-width:899px)": {
            padding: "30px 35px 36px",
        },
        "@media (max-width:599px)": {
            padding: "28px",
        },
        "& .MuiBox-root": {
            marginBottom: "34px",
            "@media (max-width:1199px)": {
                marginBottom: "30px",
            },
            "@media (max-width:899px)": {
                marginBottom: "25px",
            },
            "@media (max-width:599px)": {
                marginBottom: "20px",
            },
        },
        "& .MuiFormHelperText-root": {
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "24px",
            color: "#373737",
            letterSpacing: "0px",
            fontFamily: "'Montserrat', sans-serif",
            margin: "0px",
            paddingBottom: "14px",
            "@media (max-width:899px)": {
                fontSize: "18px",
                lineHeight: "22px",
            },
            "@media (max-width:599px)": {
                fontSize: "16px",
                lineHeight: "20px",
            },
        },
        "& .max_number": {
            fontSize: "14px",
            lineHeight: "18px"
        },
        "& .MuiTypography-root": {
            lineHeight: "24px",
        },
        "& .MuiButton-root": {
            marginTop: "10px",
            maxWidth: "100%",
            height: "69px",
            "@media (max-width:899px)": {
                height: "50px",
            },
            "@media (max-width:599px)": {
                height: "40px",
            },
        },
        "& .MuiOutlinedInput-root": {
            height: "54px",
            padding: "0px",
            "@media (max-width:1199px)": {
                height: "50px",
                lineHeight: "22px",
            },
            "@media (max-width:899px)": {
                height: "44px",
                lineHeight: "20px",
            },
            "@media (max-width:599px)": {
                height: "40px",
                lineHeight: "18px",
            },
            "& .MuiOutlinedInput-input": {
                padding: "13px",
                "@media (max-width:899px)": {
                    padding: "10px",
                },
                "@media (max-width:599px)": {
                    padding: "8px 10px",
                },
            },
            "& .MuiIconButton-root": {
                marginRight: "5px",
                "&:hover": { background: "none" },
                "& .MuiSvgIcon-root": {
                    fill: "#373737",
                    width: "35px",
                    height: "28px",
                    "@media (max-width:899px)": {
                        width: "22px",
                        height: "22px",
                    },
                    "@media (max-width:599px)": {
                        width: "20px",
                        height: "20px",
                    },
                },
            },
        },
        "& .password_msg": {
            fontSize: "16px",
            lineHeight: "19px",
            color: "#C01515",
            marginTop: "26px",
            textAlign: "center",
            "@media (max-width:899px)": {
                fontSize: "14px",
            },
            "@media (max-width:599px)": {
                fontSize: "12px",
            },
        },
        "& .success": {
            color: "#248F17",
        },
    },
}))

const ChangePassword_tab = ({ auth }) => {
    const classes = useStyles();
    const intlContext = useContext(IntlContext);
    const gothe_mile = intlContext?.messages;
    const [error, setError] = useState("");
    const [messageBox, setMessageBox] = useState(false);
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [newShowPassword, setNewShowPassword] = useState(false);
    const [reTypeShowPassword, setReTypeShowPassword] = useState(false);

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    return (<>
        {loader && <PreLoader />}
        <Box className={classes.root}>
            <Formik
                initialValues={{
                    current: "",
                    new: "",
                    retype: "",
                }}
                validationSchema={Yup.object().shape({
                    current: Yup.string()
                        .required("field is required")
                        .min(6, "Min 6 characters required."),
                    new: Yup.string()
                        .required("field is required")
                        .min(6, "Min 6 characters required."),
                    retype: Yup.string()
                        .required("field is required")
                        .when("new", {
                            is: (val) => (val && val.length > 0 ? true : false),
                            then: Yup.string().oneOf(
                                [Yup.ref("new")],
                                ("Password does not match")
                            )
                        })
                })}
                onSubmit={async (data, { resetForm }) => {
                    let payload = {
                        current: data?.current,
                        new: data?.new,
                        retype: data?.retype,
                        _id: auth?.data?._id
                    };
                    setLoader(true);
                    try {
                        const { data, status } = await axiosInstance.post(URLS.doChangePassword, payload);
                        if (status === 200) {
                            if (data?.value === true) {
                                setLoader(false);
                                setMessageBox(true);
                                setError("");
                                setTimeout(() => {
                                    setMessageBox(false);
                                    EventEmitter.dispatch('processafterchangepassword');
                                }, 3000);
                                resetForm();
                            } else {
                                setLoader(false);
                                setError(data?.error);
                                setMessageBox(false);
                            }
                        }
                        else {
                            setLoader(false);
                        }
                    }
                    catch (error) {
                        setLoader(false);
                    }
                }}
            >
                {({ errors,
                    touched,
                    handleBlur,
                    handleChange,
                    values,
                    handleSubmit
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box>
                            <FormHelperText className="field_label">{gothe_mile?.old_password}</FormHelperText>
                            <InputTextField
                                fullWidth
                                type={showPassword ? "text" : "password"}
                                name="current"
                                error={Boolean(touched?.current && errors?.current)}
                                helperText={touched?.current && errors?.current}
                                value={values?.current}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? (<Visibility />) : (<VisibilityOff />)}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box>
                            <FormHelperText className="field_label">{gothe_mile?.new_password}<span className="max_number">({gothe_mile?.max_character})</span></FormHelperText>
                            <InputTextField
                                fullWidth
                                type={newShowPassword ? "text" : "password"}
                                name="new"
                                error={Boolean(touched?.new && errors?.new)}
                                helperText={touched?.new && errors?.new}
                                value={values?.new}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setNewShowPassword(!newShowPassword)}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {newShowPassword ? (<Visibility />) : (<VisibilityOff />)}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box>
                            <FormHelperText className="field_label">{gothe_mile?.retype_password}</FormHelperText>
                            <InputTextField
                                fullWidth
                                type={reTypeShowPassword ? "text" : "password"}
                                name="retype"
                                error={Boolean(touched?.retype && errors?.retype)}
                                helperText={touched?.retype && errors?.retype}
                                value={values?.retype}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setReTypeShowPassword(!reTypeShowPassword)}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {reTypeShowPassword ? (<Visibility />) : (<VisibilityOff />)}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Button type="submit" variant="browse_blue">{gothe_mile?.save}</Button>
                    </form>
                )}
            </Formik>
            <Typography className={`password_msg  ${messageBox ? "success" : ""}`} >{messageBox ? gothe_mile?.message?.password_changed : error}</Typography>
        </Box >
    </>)
}
export default ChangePassword_tab;