import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { fetchFromStorage } from "src/helpers/context/Storage";
import { createStyles, makeStyles } from "@mui/styles";
import { IntlContext } from "src/App";

const useStyles = makeStyles((theme) => createStyles({
    root: {
        minHeight: "500px",
        margin: "30px 0",
        textAlign: "center",
        "& .iframe_form": {
            minHeight: "100vh",
            width: "100%",
        },
        "& .MuiButton-root": {
            height: "36px",
            fontSize: "14px",
            padding: "6px 18px",
        }
    }
}))

const TestForm = () => {
    const classes = useStyles();
    const location = useLocation();
    const intlContext = useContext(IntlContext);
    const gothe_mile = intlContext?.messages;
    const redux_auth = useSelector((state) => state.ActionReducer.authdata);
    const auth = redux_auth ? redux_auth : fetchFromStorage('authdata');
    const lastSegment = location.pathname.split('/').pop();
    const lastSegment2 = location.pathname.split('/')[2];
    const { state } = useLocation();

    return (
        <>
            <Box className="main_container">
                <Box className={classes.root}>
                    <iframe
                        src={auth
                            ?
                            state ? `https://www.classmarker.com/online-test/start?quiz=${lastSegment2}&cm_fn=${auth?.data?.firstName}&cm_ln=${auth?.data?.lastName}&cm_e=${auth?.data?.email}&cm_user_id=${lastSegment}-${auth?.data?._id}-${state?.orderId}`
                                : `https://www.classmarker.com/online-test/start?quiz=${lastSegment2}&cm_fn=${auth?.data?.firstName}&cm_ln=${auth?.data?.lastName}&cm_e=${auth?.data?.email}&cm_user_id=${lastSegment}-${auth?.data?._id}`
                            : `https://www.classmarker.com/online-test/start?quiz=${lastSegment2}&cm_user_id=${lastSegment}`}
                        frameBorder="0"
                        className="iframe_form"
                    >
                    </iframe >
                    {auth ?
                        <Box sx={{ mt: "40px" }}>
                            <Button variant="blue" href="/dashboard">{gothe_mile?.goBackDashboard}</Button>
                        </Box>
                        :
                        <Box sx={{ mt: "40px" }}>
                            <Button variant="blue" href="/testfree">{gothe_mile?.goBackFreeTest}</Button>
                        </Box>
                    }
                </Box>
            </Box>
        </>
    )
}
export default TestForm;