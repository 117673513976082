import React from "react";
import { Dialog, Grow, DialogContent, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            "& .MuiPaper-root": {
                maxWidth: "450px",
                width: "100%",
                color: "#373737",
                padding: "15px",
                borderRadius: "15px",
                "@media (max-width:520px)": {
                    maxWidth: "320px"
                },
                "& .close": {
                    display: "flex",
                    marginLeft: "auto",
                    fill: "#405CFB",
                    cursor: "pointer",
                    width: "26px",
                    height: "26px",
                    "@media (max-width:899px)": {
                        width: "24px",
                        height: "24px",
                    },
                    "@media (max-width:599px)": {
                        width: "22px",
                        height: "22px",
                    },
                },
                "& .MuiDialogContent-root": {
                    padding: "20px",
                    textAlign: "center",
                    "@media (max-width:899px)": {
                        padding: "18px",
                    },
                    "@media (max-width:599px)": {
                        padding: "15px",
                    },
                    "@media (max-width:460px)": {
                        padding: "12px",
                    },
                    "& .MuiTypography-root": {
                        color: "#405CFB",
                        fontSize: "20px",
                        "@media (max-width:899px)": {
                            fontSize: "18px",
                        },
                    }
                },
            }
        }
    }))

const MessageBox = ({ open, handleClose, content }) => {
    const classes = useStyles();

    return (
        <>
            <Dialog
                className={classes.root}
                open={open}
                onClose={handleClose}
                TransitionComponent={Grow}
                scroll={"body"}
            >
                <Box sx={{ textAlign: "right" }}>
                    <CloseIcon className="close" onClick={handleClose} />
                </Box>
                <DialogContent>{content}</DialogContent>
            </Dialog>
        </>
    );
};
export default MessageBox;
