import React, { useContext, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import { InputTextField } from "../../component/UI/textfield";
import { createStyles, makeStyles } from "@mui/styles";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import VerifyOTP from "./VerifyOtp";
import MessageBox from "../../component/UI/MessageBox";
import PreLoader from "src/component/Loader";
import DialogBox from "src/view/component/UI/DialogBox";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: "696px",
            "& .MuiTypography-root": {
                textAlign: "left",
                paddingBottom: "8px",
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: "500",
                "@media (max-width:899px)": {
                    lineHeight: "20px",
                    fontSize: "16px",
                },
            },
            "& .MuiOutlinedInput-root": {
                height: "54px",
                padding: "0px",
                lineHeight: "20px",
                "@media (max-width:899px)": {
                    height: "45px",
                },
                "@media (max-width:599px)": {
                    height: "35px",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "15px 12px",
                    fontSize: "20px",
                    // lineHeight: "24px",
                    height: "24px",
                    "@media (max-width:899px)": {
                        fontSize: "16px",
                        height: "18px",
                        padding: "12px"
                    },
                },
            },
            "& .conti_btn": {
                width: "100%",
                maxWidth: "536px",
                margin: "27px 0 45px",
                "@media (max-width:899px)": {
                    height: "60px",
                    margin: "22px 0 35px",
                },
                "@media (max-width:599px)": {
                    margin: "20px 0",
                    height: "40px",
                },
            },
            "& .signin_link": {
                color: "#405CFB",
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: "600",
                textDecoration: "underline",
                "@media (max-width:600px)": {
                    fontSize: "14px",
                },
            },
        },
    })
);


const ForgotPassword = ({ closePasswordPopup }) => {
    const classes = useStyles();
    const intlContext = useContext(IntlContext);
    const gothe_mile = intlContext?.messages;
    const [otpPopup, setOtpPopup] = useState(false);
    const [userId, setUserId] = useState();
    const [userEmail, setUserEmail] = useState();
    const [invalidUserMessage, setInvalidUserMessage] = useState(false);
    const [loader, setLoader] = useState(false);

    const closeOtpPopup = () => {
        setOtpPopup(!otpPopup)
    };

    return (
        <>
            {loader && <PreLoader />}
            <MessageBox open={invalidUserMessage} handleClose={() => setInvalidUserMessage(!invalidUserMessage)}
                content={<Typography className="text-danger">{gothe_mile?.message?.invalidUserMsg}</Typography>}
            />
            <DialogBox open={otpPopup} handleClose={closeOtpPopup} title={gothe_mile?.VerifyOTP} content={<VerifyOTP userId={userId} userEmail={userEmail} closeOtpPopup={closeOtpPopup} />} />
            <Formik
                initialValues={{
                    email: "",
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .required("Email is required")
                        .email("Must be a valid email")
                        .max(255)
                })}
                onSubmit={async (data) => {
                    let payload = {
                        email: data?.email,
                    };
                    setLoader(true);
                    try {
                        const { data, status } = await axiosInstance.post(URLS.forgotPassword, payload);
                        if (status === 200) {
                            if (data?.value === true) {
                                setLoader(false);
                                setUserEmail(payload?.email);
                                setUserId(data?.data?.userId);
                                setOtpPopup(true);
                            } else {
                                setLoader(false);
                                setInvalidUserMessage(true);
                            }
                        } else { setLoader(false); }
                    }
                    catch (error) { setLoader(false); }
                }}
            >
                {({ errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box className={classes.root}>
                            <Typography>{gothe_mile?.email_address}</Typography>
                            <Box>
                                <InputTextField
                                    fullWidth
                                    type="email"
                                    name="email"
                                    value={values?.email}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Enter Your Email"
                                />
                            </Box>
                            <Button type="submit" variant="browse_blue" className="conti_btn">{gothe_mile?.continue}</Button>
                            <NavLink to="#" onClick={closePasswordPopup} className="signin_link">{gothe_mile?.signIn}</NavLink>
                        </Box>
                    </form>)}
            </Formik>
        </>
    );
};

export default ForgotPassword;
