import React from "react";
import Page from "../../component/Page";
import CCPComponent from "../component/Cart/CCPAccordian";

const CartPage = () => {
    return (
        <>
            <Page title="Go the Extra Mile | My Cart">
                < CCPComponent />
            </Page>
        </>
    )
};

export default CartPage;