import React from "react";
import Page from "../../component/Page";
import Home from "../component/Home";

const HomePage = () => {
  return (
    <>
      <Page title="Go the Extra Mile | Home">
        <Home />
      </Page>
    </>
  )
};

export default HomePage;
