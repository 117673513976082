import React from "react";
import { Box, Button, Typography, TextField, TextareaAutosize } from "@mui/material";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import { Formik } from "formik";
import * as Yup from "yup";

const ContactUs = ({ gothe_mile, setContactMessage, setLoader }) => {

    return (
        <>
            <Box id="contact" className="contactus-wrap bg-light-blue sm-title">
                <Box className="main_container">
                    <Typography variant="h2" className="sub-title">{gothe_mile?.home?.contact_us}</Typography>
                    <Box className="form-wrap">
                        <Formik
                            initialValues={{ name: "", email: "", message: "" }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().trim().required(gothe_mile?.validation?.required_message),
                                email: Yup.string()
                                    .required(gothe_mile?.validation?.enterEmailId)
                                    .email(gothe_mile?.validation?.valid_email),
                                message: Yup.string().trim().required(gothe_mile?.validation?.required_message),
                            })}
                            onSubmit={async (data, { resetForm }) => {
                                let payload = {
                                    name: data?.name,
                                    email: data?.email,
                                    message: data?.message,
                                    mobile: "",
                                    subject: data?.message,
                                };
                                setLoader(true);
                                try {
                                    const { data, status } = await axiosInstance.post(
                                        URLS.contactUsSave,
                                        payload
                                    );
                                    if (status === 200) {
                                        if (data?.value === true) {
                                            setLoader(false);
                                            let contactId = {
                                                _id: data?.data?._id,
                                            };
                                            setLoader(true);
                                            const { item, status } = await axiosInstance.post(
                                                URLS.contactUsEmail,
                                                contactId
                                            );
                                            if (status === 200) {
                                                setLoader(false);
                                                setContactMessage(true);
                                                resetForm();
                                            } else {
                                                setLoader(false);
                                                resetForm();
                                            }
                                        } else { setLoader(false); }
                                    } else { setLoader(false); }
                                } catch (error) { setLoader(false); }
                            }}
                        >
                            {({
                                errors, touched, handleBlur, handleChange, handleSubmit, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <label>{gothe_mile?.home?.full_name}*</label>
                                    <TextField
                                        id="outlined-basic"
                                        type="text"
                                        name="name"
                                        value={values?.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(touched?.name && errors?.name)}
                                        helperText={touched?.name && errors?.name}
                                        variant="outlined"
                                    />
                                    <label>{gothe_mile?.home?.email}*</label>
                                    <TextField
                                        id="outlined-basic"
                                        type="email"
                                        name="email"
                                        value={values?.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(touched?.email && errors?.email)}
                                        helperText={touched?.email && errors?.email}
                                        variant="outlined"
                                    />
                                    <label>{gothe_mile?.home?.your_msg}*</label>
                                    <TextareaAutosize
                                        minRows={2}
                                        type="text"
                                        name="message"
                                        value={values?.message}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(touched?.message && errors?.message)}
                                        helperText={touched?.message && errors?.message}
                                        style={{ width: 440, padding: "8px 10px" }}
                                    />
                                    {errors?.message && <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-contained css-15vc7xg-MuiFormHelperText-root">{errors?.message}</p>}
                                    <Button type="submit" variant="blue" className="send_btn">{gothe_mile?.send}</Button>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Box>
        </>
    )
};
export default React.memo(ContactUs);