import { createTheme, colors } from "@mui/material";
import typography from "./typography";
import button from "./button"

const theme = createTheme({
  palette: {
    background: {
      dark: "#ffffff",
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#a4a4a4",
      white: "#ffffff",
    },
  },

  components: button,
  typography,
});

export default theme;
