export const Config = {
    appBaseURL: process.env.REACT_APP_URL,
    baseURL: process.env.REACT_APP_API_URL,
    mediaURL: process.env.REACT_APP_MEDIA_URL,
    blogURL: process.env.REACT_APP_BLOG_URL,
    GoogleAppID: process.env.REACT_APP_GOOGLE_ID,
    FacebookAppID: process.env.REACT_APP_FACEBOOK_ID,
    AppleAppID: process.env.REACT_APP_APPLE_ID,
    StripeApiURL: process.env.REACT_APP_STRIPE_API_URL,
    StripeApikeySK: process.env.REACT_APP_STRIPE_APIKEY_SK,
}