import React from 'react';
import { Dialog, Grow, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createStyles, makeStyles } from '@mui/styles';
import './ui.scss';
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '696px',
        color: '#373737',
        textAlign: 'center',
        padding: '72px 80px',
        borderRadius: '20px',
        // "& .close": {
        //   display: "flex",
        //   marginLeft: "auto",
        //   fill: "#405CFB",
        //   cursor: "pointer",
        //   width: "26px",
        //   height: "26px",
        // },
        '@media (max-width:991px)': {
          padding: '40px 30px',
          maxWidth: '580px',
        },
        '@media (max-width:600px)': {
          padding: '20px 15px',
          margin: '15px',
          maxWidth: '330px',
        },
        '& .dialog_box_title': {
          width: '100%',
          fontSize: '50px',
          fontWeight: '700',
          lineHeight: '61px',
          letterSpacing: '0',
          padding: '0 0 27px 0',
          '@media (max-width:991px)': {
            fontSize: '40px',
            paddingBottom: '30px',
          },
          '@media (max-width:600px)': {
            fontSize: '28px',
            paddingBottom: '20px',
          },
        },
        '& .MuiDialogContent-root': {
          padding: '0px',
          textAlign: 'center',
        },
      },
    },
  })
);

const DialogBox = ({ open, handleClose, title, content, className }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        className={`${classes.root} ${className}`}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
        scroll={'body'}
      >
        {/* <CloseIcon className="close" onClick={handleClose} /> */}

        <DialogTitle className="dialog_box_title">{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    </>
  );
};
export default DialogBox;
