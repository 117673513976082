import React, { useState, useContext, useEffect } from "react";
import { Typography } from "@mui/material";
import "./home.scss";
import { IntlContext } from "src/App";
import PreLoader from "src/component/Loader";
import MessageBox from "../UI/MessageBox";
import { useLocation } from "react-router-dom";
import SliderComp from "./Slider";
import Testimonial from "./Testimonial";
import LinkToApp from "./LinkToApp";
import AboutUs from "./AboutUs";
import SubscripionOption from "./SubscriptionOption";
import ContactUs from "./ContactUs";
import WhatappContact from "./WhatsappContact";

const Home = () => {
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [loader, setLoader] = useState(false);
  const [contactMessage, setContactMessage] = useState(false);
  const [timeInterval, setTimeInterval] = useState(true);

  const goToViolation = (classId) => {
    const violation = document.getElementById(classId);
    window.scrollTo({
      top: violation?.offsetTop,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    const url = window.location.href;
    const spliturl = url.split("#");
    const id = spliturl[spliturl.length - 1];
    var redirectId = id ? id : location?.state?.sectionId
    setTimeout(() => {
      redirectId && goToViolation(redirectId);
    }, 900);
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setTimeInterval(true)
    }, 900);
  }, [timeInterval]);

  return (
    <>
      {timeInterval && (
        <>
          {loader && <PreLoader />}
          <MessageBox open={contactMessage} handleClose={() => setContactMessage(!contactMessage)}
            content={<Typography>{gothe_mile?.message?.contactMsg}</Typography>}
          />
          <SliderComp gothe_mile={gothe_mile} setLoader={setLoader} />
          <LinkToApp gothe_mile={gothe_mile} />
          <AboutUs gothe_mile={gothe_mile} />
          <SubscripionOption gothe_mile={gothe_mile} setLoader={setLoader} />
          <Testimonial gothe_mile={gothe_mile} setLoader={setLoader} />
          <ContactUs gothe_mile={gothe_mile} setContactMessage={setContactMessage} setLoader={setLoader} />
          <WhatappContact gothe_mile={gothe_mile} />
        </>
      )}
    </>
  );
};

export default Home;
