import React, { useContext } from "react";
import { Box, Typography, Link, List, ListItem } from "@mui/material";
import { IntlContext } from "src/App";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    root: {
        background: "#2E2E2E",
        padding: "80px 0 58px",
        "@media (max-width:1199px )": {
            padding: "60px 0 48px",
        },
        "& .MuiTypography-root": {
            color: "#ffffff"
        },
        "& .faq_subtitle": {
            fontSize: "22px",
            lineHeight: "27px",
            paddingTop: "37px",
            "@media (max-width:1199px )": {
                fontSize: "20px",
                lineHeight: "24px",
                paddingTop: "30px",
            },
            "@media (max-width:899px )": {
                fontSize: "18px",
                lineHeight: "20px",
                paddingTop: "20px",
            },
            "@media (max-width:599px )": {
                fontSize: "16px",
                lineHeight: "18px",
                paddingTop: "15px",
            },
        }
    },
    root_: {
        margin: "30px 0 50px",
        "& .title": {
            fontSize: "26px",
            fontWeight: "700",
            marginBottom: "16px"
        },
        "& .sales_terms": {
            fontSize: "14px",
            fontWeight: "500",
            marginBottom: "10px",
            lineHeight: "22px",
            letterSpacing: "0.5px"
        },
        "& .MuiListItem-root": {
            display: "list-item",
            padding: "0px 0px 0px 4px",
            "& .sales_terms": {
                lineHeight: "20px",
            },
        },
    },
}))

const TermsOfUse = () => {
    const classes = useStyles();
    const intlContext = useContext(IntlContext);
    const gothe_mile = intlContext?.messages;
    return (
        <>
            <Box className="main_container">
                <Box sx={{ mt: "40px" }}>
                    <Typography className="my_cart_title">{gothe_mile?.termsOfuse?.title}</Typography>
                </Box>
                <Box className={classes.root_}>
                    <Typography className="title">{gothe_mile?.termsOfuse?.subtitle}</Typography>
                    <Typography className="sales_terms">Welcome to our website. This website is owned and operated by Quiz Time In Critical Care Pty Ltd (ACN 614 668 973) its successors and assignees (<strong>we, our</strong> or <strong>us</strong>). It is available at:<Link target="_blank" href="https://gotheextramile.com/" className="email_link"> www.gotheextramile.com </Link>(Site) and may be available through other addresses or channels.</Typography>
                    <Typography className="sales_terms">These Terms of Use <strong>(Terms)</strong> govern you, the person, organisation or entity that accesses and/or uses our Site (referred to as <strong>you</strong> or <strong>your</strong>) and form a contract between you and us if you access our Site. Please read these Terms carefully. If you have any questions, please contact us using the contact details below. Your use of the Site indicates that you have had sufficient opportunity to access these Terms and that you have read and accepted these Terms. You should immediately cease accessing and using this Site if you do not accept these Terms.</Typography>
                    <List sx={{ listStyle: "decimal", p: "0 0 0 40px" }}>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Information:</strong> The information, including statements, opinions and documents, contained in this Site <strong>(Information)</strong> is for general information purposes only. It does not take into account your specific needs, objectives or circumstances, and it is not advice. Any reliance you place on the Information is at your own risk. Before acting on any Information, we recommend that you consider whether it is appropriate for your circumstances, carry out your own research and seek professional advice, where necessary.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Amendment:</strong> The Information and Terms may be amended without notice from time to time in our sole discretion. Your use of our Site following any amendments indicates that you accept the amendments. You should check the Terms regularly to ensure you are aware of any changes, and only proceed to use the Site if you accept and will comply with the new Terms.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Your warranties</strong> You warrant to us that you have the legal capacity to enter these Terms and form a contract, and that you have read and understood these Terms, before using the Site.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Licence to use the Site:</strong> We grant you a non-exclusive, royalty-free, revocable, worldwide, non- transferable right and licence to use the Site for your personal, non-commercial use, in accordance with these Terms. All other uses are prohibited without our prior written consent.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Prohibited conduct: You must not:</strong></Typography>
                            <Typography className="sales_terms">(a). Use the Site for any activities, or post or transmit any material from the Site:</Typography>
                            <List sx={{ listStyle: "disc", p: "0 0 0 40px" }}>
                                <ListItem><Typography className="sales_terms">unless you hold all necessary rights, licences and consents to do so;</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">that infringes the intellectual property or other rights of any person;</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">that would cause you or us to breach any law, regulation, rule, code or other legal obligation;</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">that defames, harasses, threatens, menaces, offends or restricts any person;</Typography>
                                </ListItem>
                                <ListItem><Typography className="sales_terms">that is or could reasonably be considered to be obscene, inappropriate, defamatory, disparaging, indecent, seditious, offensive,pornographic, threatening, abusive, liable to incite racial hatred, discriminatory, blasphemous, in breach of confidence or in breach of privacy; or</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">that would bring us, or the Site, into disrepute;</Typography></ListItem>
                            </List>
                            <Typography className="sales_terms">(b). Interfere with or inhibit any user from using the Site;</Typography>
                            <Typography className="sales_terms">(c). Use the Site to send unsolicited email messages;</Typography>
                            <Typography className="sales_terms">(c). Attempt to or tamper with, hinder or modify the Site, knowingly transmit viruses or other disabling features, or damage or interfere with the Site, including but not limited to the use of trojan horses, viruses, or piracy or programming routines that may damage or interfere with the Site; or</Typography>
                            <Typography className="sales_terms">(e). Facilitate or assist a third party to do any of the above acts.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Copyright and intellectual property rights:</strong> Our Site contains material which is owned by or licensed to us and is protected by Australian and international laws, including but not limited to the trademarks, trade names, software, content, design, images, graphics, appearance, layout and look of our Site. We own or are licenced to use the copyright which subsists in all creative and literary works displayed on the Site.</Typography>
                            <Typography className="sales_terms">You agree that, as between you and us, we own all intellectual property rights in the Site, and that nothing in these Terms constitutes a transfer of any intellectual property rights. Your use of the Site does not grant you a licence to, or act as a right to, use any of the intellectual property, whether registered or unregistered, displayed on the Site without the express written permission of the owner.</Typography>
                            <Typography className="sales_terms">You must not breach any copyright or intellectual property rights connected with the Site. This includes but is not limited to:</Typography>
                            <Typography className="sales_terms">(a). altering or modifying any of the code or the material on the Site;</Typography>
                            <Typography className="sales_terms">(b). causing any of the material on the Site to be framed or embedded in another website;</Typography>
                            <Typography className="sales_terms">(c). creating derivative works from the content of the Site; or</Typography>
                            <Typography className="sales_terms">(d). using the Site for commercial purposes.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Privacy:</strong> We are committed to protecting your privacy. Please read our Privacy Policy which is available on the Site. By agreeing to these Terms, you agree to accept our Privacy Policy.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Your content:</strong> If you choose to add any content on the Site, you:</Typography>
                            <Typography className="sales_terms">(a). warrant to us that you have all necessary rights to post the content;</Typography>
                            <Typography className="sales_terms">(b). grant us a perpetual, non-exclusive, royalty-free, irrevocable, worldwide and transferable right and licence to use that content in any way (including but not limited to, by reproducing, changing, and communicating the content to the public) and permit us to authorise any other person to do the same thing; and</Typography>
                            <Typography className="sales_terms">(c). you consent to any act or omission which would otherwise constitute an infringement of your moral rights, and if you add any content in which any third party has moral rights, you must ensure that the third party consents in the same manner.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Third party information:</strong> The Site may contain third party information, including but not limited to user comments, guest articles and advertisements (Third Party Information). We do not control, recommend, endorse, sponsor or approve Third Party Information, including any information, products or services mentioned in Third Party Information. You should make your own investigations with respect to the suitability of Third Party Information for you.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Third party links and websites:</strong>  This Site may contain links to websites owned by third parties (Third Party Sites). We do not control, recommend, endorse, sponsor or approve Third Party Sites, including any information, products or services mentioned on Third Party Sites. You should make your own investigations with respect to the suitability of Third Party Sites for you.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Reservation of rights:</strong> We reserve the right to amend or delete any and all of your content, Third Party Information and/or Third Party Sites, and to block any user, if we believe that there is a violation of these Terms, or for any other reason, in our sole discretion.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Delays and outages:</strong> We are not responsible for any delays or interruptions to the Site. We will use commercially reasonable efforts to minimise delays and interruptions. We cannot warrant that the Site will be available at all times or at any given time. We may at any time and without notice to you, discontinue the Site in whole or in part. We are not responsible for any loss, cost, damage or liability that may result from our discontinuance of the Site.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Limitation of liability:</strong> To the extent permitted by law, we exclude all liability for any loss, damage, costs or expense, whether direct, indirect, incidental, special and/or consequential including loss of profits, suffered by you or any third party, or claims made against you or any third party which result from any use of or access to, or any inability to use or access, the Site.</Typography>
                            <Typography className="sales_terms">To the extent permitted by law, we exclude all representations, guarantees, warranties or terms (whether express or implied) other than those expressly set out in these Terms, and the Australian Consumer Law to the extent applicable.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Disclaimer:</strong> The Site is provided to you without warranties, express or implied, including but not limited to implied warranties of merchantability and/or fitness for a particular purpose. We do not warrant that the functions contained in any material on the Site or your access to the Site will be error free, that any defects will be corrected, that the Site or the server which stores and transmits material to you are free of viruses or any other harmful components, or that the Site will operate on a continuous basis or be available at any time.</Typography>
                            <Typography className="sales_terms">While we endeavour to keep the Site and Information up to date and correct, we make no representations, warranties or guarantee, express or implied, about:</Typography>
                            <Typography className="sales_terms">(a). the completeness, accuracy, reliability, suitability or availability of any Information, images, products, services, or related graphics contained on the Site for any purpose;</Typography>
                            <Typography className="sales_terms">(b). You read, use, and act on information contained on the Site, Third Party Information and/or Third Party Sites, strictly at your own risk.Third Party Information; ord</Typography>
                            <Typography className="sales_terms">(c). Third Party Sites.</Typography>
                            <Typography className="sales_terms">You read, use, and act on information contained on the Site, Third Party Information and/or Third Party Sites, strictly at your own risk.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Indemnity:</strong> By using the Site, you agree to defend and fully indemnify and hold us (and our officers, directors, employees, contractors and agents) harmless from and against all claims, actions, suits, demands, damages, liabilities, costs or expenses (including legal costs and expenses on a full indemnity basis), including in tort, contract or negligence, arising out of or connected to:</Typography>
                            <Typography className="sales_terms">(a).  your use of or access to the Site;</Typography>
                            <Typography className="sales_terms">(b). any breach by you of these Terms; or</Typography>
                            <Typography className="sales_terms">(c). any wilful, unlawful or negligent act or omission by you.</Typography>
                            <Typography className="sales_terms">This defence and indemnification obligation will survive these Terms and your use of the Site. These Terms, and any rights and licences granted hereunder, may not be transferred or assigned by you, but may be assigned by us without restriction.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Breach:</strong> You may only use the Site for lawful purposes and in a manner consistent with the nature and purpose of the Site. By using the Site, you agree that the exclusions and limitations of liability set out in these Terms are reasonable. If you do not think they are reasonable you must not use the Site. We reserve the right to remove any and all content found to be in breach of intellectual property rights, including without limitation copyright, or which in our opinion is deemed inappropriate and/or illegal. If you breach these Terms, we reserve the right to block you from the Site and to enforce our rights against you. If we do not act in relation to a breach of these Terms by you, this does not waive our rights to act with respect to subsequent or similar breaches of these Terms by you. All rights not expressly granted in these Terms are reserved.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Exclusion of competitors:</strong> You are prohibited from using the Site, including the Information, in any way that competes with our business. If you breach this term, we will hold you responsible for any loss that we may sustain, and hold you accountable for any profits that you may make from non- permitted use. We reserve the right to exclude any person from using the Site and Information, in our sole discretion.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Enforceability:</strong> If any provision of these Terms is found to be illegal, invalid or unenforceable by a court of law, then the provision will not apply in that jurisdiction and is deemed not to have been included in these Terms in that jurisdiction. This will not affect the remainder of these Terms, which continue in full force and effect.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Further assurances:</strong> Each party must, at its own expense, do everything reasonably necessary to give full effect to these Terms and the events contemplated by them.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Termination:</strong> These Terms are effective until terminated by us, which we may do at any time and without notice to you. In the event of termination, all restrictions imposed on you by these Terms and limitations of liability set out in the Terms will survive.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Disputes:</strong> You agree to use your best endeavours to use mediation and negotiation to resolve any dispute arising out of or relating to these Terms, prior to resorting to an external dispute resolution process. Please notify us in writing of any dispute you may have.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Jurisdiction:</strong> Your use of the Site and any dispute arising out of your use of it is subject to the laws of New South Wales and the Commonwealth of Australia. These Terms are governed by the laws of New South Wales and the Commonwealth of Australia and subject to the exclusive jurisdiction of the courts operating in New South Wales. The Site may be accessed throughout Australia and overseas. We make no representation that the Site complies with the laws (including intellectual property laws) of any country outside Australia. If you access the Site from outside Australia, you do so at your own risk and are responsible for complying with the laws in the place where you access the Site.</Typography>
                        </ListItem>
                    </List>
                    <Typography className="sales_terms">For questions and notices, please contact us at:</Typography>
                    <Typography className="sales_terms">Quiz Time in Critical Care Pty Ltd (ACN 614 368 973)<br /><strong>Email: <Link href="mailto:sumesharora1@gotheextramile.com" className="email_link">sumesharora1@gotheextramile.com
                    </Link></strong></Typography>
                    <Typography className="sales_terms"><strong>Last update: 2 September 2016</strong><br />LegalVision ILP Pty Ltd owns the copyright in this document and use without permission is prohibited.</Typography>
                </Box>
            </Box>
        </>
    )
}
export default TermsOfUse;