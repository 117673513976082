import React from "react";
import { Typography } from "@mui/material";
import { ReactComponent as CirclePlusIcon } from "src/assets/images/icons/feather-plus-circle.svg";
import AccordionComp from "../UI/Accordian";

const GeneralQuery = () => {
  // const Acc_summery = () => {
  //     return (
  //         <>
  //             <Typography>Who can use the Go the Extra Mile (Go the Extra Mile) website?</Typography>
  //         </>
  //     )
  // }

  // const Acc_content = () => {
  //     return (
  //         <>
  //             <Typography>
  //                 At Go the Extra Mile you can test your knowledge of intensive care in the form of multiple choice questions. The site contains questions for both physicians and nurses. Doctors may use this site for preparing for the exams (e.g. European Diploma in Intensive Care, FFICM (UK), DM Intensive Care (India), or CICM (Australia). The site contains questions for both the primary exam and final fellowship exam. The supervisors of training may use the orientation packages for introducing the speciality to the new recruits. Cardiothoracic and neurointensive care collections may be utilised for acclimatisation to the speciality ICUs.
  //             </Typography>
  //             <Typography>
  //                 The intensive care nurses may use the site for orientation. The nurse educators may assign the orientation packs to the new nurses. A certificate of completion may be emailed to both the nurse and the supervisor upon successful completion of the assignment
  //             </Typography>
  //             <Typography>
  //                 Experienced nurses may use the advanced nursing tests to evaluate their proficiency.
  //             </Typography>
  //         </>
  //     )
  // }

  const acc_summery = [
    {
      icon: <CirclePlusIcon />,
      summery: <Typography>What is Go the Extra Mile?</Typography>,
      content: (
        <>
          <Typography>
            Go the Extra Mile (GTEM) is an online platform for MCQ in Critical
            Care Medicine. The MCQ are organized in the form of tests. There are
            two kinds of tests:
          </Typography>
          <ul>
            <li>
              Exam Style tests (For Exam Practice)
              <ul>
                <li>30 MCQ per test</li>
                <li>30-60 minutes per test</li>
                <li>Immediate score at the end of test</li>
                <li>Complete Feedback at the end of the test</li>
                <li>
                  Unless otherwise indicated, all tests are exam style tests
                </li>
                <li>Same questions as Learn-X Style tests</li>
              </ul>
            </li>
            <li>
              Learn-X Style Tests (For learning & Revision)
              <ul>
                <li>10 MCQ per test</li>
                <li>999 minutes available to complete the test</li>
                <li>Feedback immediately after every QUESTION</li>
                <li>Feedback again at completion of test</li>
                <li>‘Lx’ prefix indicates Learn-X style tests</li>
                <li>Available in Learn-X or EDIC Omnibus + Learn-X.</li>
                <li>Same questions as Exam Style tests.</li>
              </ul>
            </li>
          </ul>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: <Typography>How many MCQ are there on GTEM?</Typography>,
      content: (
        <>
          <Typography>
            GTEM has over 2500 MCQ. To our knowledge, GTEM is world’s largest
            MCQ database for Critical Care Medicine. The MCQ are original,
            written at Go the Extra Mile and guest authors
          </Typography>
          <Typography>
            New MCQ, when available, are added automatically to the dashboard of
            the subscribers.
          </Typography>
          {/* <Typography>
            The questions which are labelled as MTOF(Mark True or False), have
            five options.Only the ones that the examinee think are correct have
            to be marked.These questions resemble type K questions.
          </Typography>
          <Typography>
            The trainees may use Go the Extra Mile for EDIC preparation in three
            different ways –
          </Typography>
          <Typography>
            a) Subscribe to an OMNIBUS – If you have gone through the curriculum
            at least once, and are now in a revision mode, you may subscribe to
            the entire collection for EDIC.Subscription to an OMNIBUS is
            available for a period of 12 months.You may attempt all tests for up
            to three times during your subscription.It covers most of the key
            topics of intensive care.The course is divided into chapters,
            e.g.cardiology, mechanical ventilation, etc.Within each topic there
            are two or more tests.You may attempt each test up to three times,
            within the subscription period(12 months).
          </Typography>
          <Typography>
            b) Subscribe to a CHAPTER – If you are going through your course
            material systematically, one topic at a time, this may be your best
            option.For example, if you have just completed reading about
            mechanical ventilation, you may choose to subscribe to the chapter,
            and attempt all the tests available on that topic.Individual chapter
            subscription is available for one month.You may attempt each test up
            to three times.The subscription is available for 12 months from the
            date of purchase.
          </Typography>
          <Typography>
            c) Subscribe to individual tests – You may subscribe to different
            tests within a topic.For example, if you have just read about
            waveform analysis on ventilator monitoring screen, you may choose to
            do the test.Most of the tests contain 30 questions.Of these,
            approximately 25 are on the topic, while the rest would be selected
            randomly from the Go the Extra Mile question bank.Please note that
            it is not always possible to segregate all the questions on a
            subtopic.
          </Typography>
          <Typography>
            d) Mock tests – You may practice mock exam to evaluate your
            preparation.The mock tests are prepared just like the real exam.
          </Typography> */}
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: <Typography>Does Go the Extra Mile have an App?</Typography>,
      content: (
        <>
          <Typography>
            Yes. ‘Critical Care MCQ’ by Go the Extra Mile is available through
            the Apple App Store and Google Play Store.
          </Typography>
          {/* <Typography>
            While preparing for the primary exam, you may use the Go the Extra
            Mile as outlined in the previous question on European Diploma in
            Intensive Care
          </Typography> */}
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: <Typography>How is GTEM different from MCQ books?</Typography>,
      content: (
        <>
          <Typography>GTEM MCQ has the following feature:</Typography>
          <ul>
            <li>Random order of MCQ when doing a test</li>
            <li>Randomisation of the order of choices when doing a test</li>
            <li>
              Exam like conditions – A Timer is running when you do a test
            </li>
            <li>
              MCQ based on videos (e.g., for Ventilator waveforms,
              Echocardiogram)
            </li>
            <li>
              MCQ based on images (e.g., ECMO) and screenshots (e.g., Ventilator
              waveforms)
            </li>
            <li>Immediate automatic score</li>
          </ul>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>How can I ascertain if GTEM is right for me?</Typography>
      ),
      content: (
        <>
          <Typography>
            Download the app. There are over 500 sample MCQ. Please do some or
            all the MCQ to see if it suits your style.
          </Typography>
          {/* <Typography>
            Under ‘Nursing’, you will find several tests, which will help manage
            your Continuing Medical Education. Each test has 30 questions. The
            pass marks are set at 70%. Each test may be taken 3 times. Upon
            passing the test, a certificate of completion will be emailed. Each
            test should take approximately 45 minutes (Factoring possible
            multiple attempts to get passing marks), and this shall be stated on
            the certificate.
          </Typography> */}
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          What type of MCQ are available on Go the Extra Mile?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            The part I of the EDIC exam has two types of questions.{" "}
            <strong> Type A</strong>(5 options for each question, of which only
            one is correct) and <strong>Type K</strong> (4 choices for each
            question, and each one has to be marked correct or incorrect). At
            GTEM, the structure of the questions is slightly different from EDIC
            Part I.
          </Typography>
          <Typography>
            The majority of the MCQ will have four options, and only one of them
            will be correct. These questions mimic type A question.
          </Typography>
          <Typography>
            The questions which are labelled as MTOF (Mark True or False), have
            five options. These questions have more than one answer.
          </Typography>
          <Typography>
            In addition, there are ‘Match the column’ style tests, in which the
            answer to each option is chosen from a drop-down menu.{" "}
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: <Typography>Can I attempt the tests more than once?</Typography>,
      content: (
        <>
          <Typography>
            Yes. The number of attempts that are available to you is outlined in
            the details of your subscription. For most subscriptions, up to
            three attempts are allowed. For mock tests, only one attempt may be
            permitted.
          </Typography>
          {/* <Typography>
            <strong>Stripe –</strong> Stripe is an online payment gateway for
            payment using a credit card or a debit card. You don’t necessarily
            need to have a Stripe account. Your card details are securely
            handled by the Stripe. Go The Extra Mile does not get any access to
            your card details.
          </Typography> */}
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          How can I keep track of the tests that I have already completed and my
          performance?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            On the GTEM website, under ‘My Tests’ you may see all the tests that
            you have purchased, and whether you have completed or not.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          I am an ICU nurse. How can GTEM to orient myself to ICU?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            Commencement of first term in the ICU may make anyone nervous.
            Managing critically ill is a complex undertaking, and the sheer
            volume of data that each patient generate may get overwhelming. But
            like any other seemingly complicated tasks, what you need to know as
            you begin your term (or career) in intensive care, may be divided
            into building blocks. These building blocks form the foundation of
            intensive care medicine.
          </Typography>
          <Typography>
            Under ‘Nursing’, you will find several tests, which will help manage
            your Continuing Medical Education. Each test has 30 questions. The
            pass marks are set at 70%. Each test may be taken 3 times. Upon
            passing the test, a certificate of completion will be emailed. Each
            test should take approximately 45 minutes (Factoring possible
            multiple attempts to get passing marks), and this shall be stated on
            the certificate.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>How are GTEM MCQ different from EDIC Exam?</Typography>
      ),
      content: (
        <>
          <Typography>
            GTEM is not endorsed by any governing body that conducts exams in
            Intensive Care Medicine. Those who have subscribed to GTEM and then
            completed EDIC Part I have provided the feedback that the MCQ on
            GTEM are relatively easier compared to the EDIC part I exam.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>Why does GTEM not provide all MCQ for free?</Typography>
      ),
      content: (
        <>
          <Typography>
            GTEM is run by a Sydney based intensivist. GTEM went live in 2017.
            100% of the profit, since its start, have been donated to charity.
            GTEM is a Silver Level ‘Champion for Children’ for UNICEF and
            ‘Proudly Supports’ Médecins Sans Frontières (Doctors Without
            Borders). In addition to the above, GTEM supports several local and
            international charities every year.
          </Typography>
          <Typography>
            GTEM does not have any advertisements. Running GTEM is expensive,
            and its only sources of revenue are the subscription fee. GTEM keeps
            its running cost low by the following – It has no dedicated office
            space and no employees. It is a one person undertaking – the
            director is also the responsible for writing questions, reviewing
            invited tests, study groups, social media and everything else that
            comes with the territory. GTEM director does not any salary or
            remuneration for his time and work towards GTEM (The work is its own
            reward).
          </Typography>
        </>
      ),
    },
  ];

  return (
    <>
      <AccordionComp acc_data={acc_summery} />
    </>
  );
};
export default GeneralQuery;
