import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import TopBar from "./TopBar";
import Footer from "./Footer/index";

const ClientLayout = () => {
  return (
    <div>
      <TopBar />
      <div>
        <Outlet />
      </div>
      <Box className="footer_section">
        <Box className="main_container">
          <Footer />
        </Box>
      </Box>
    </div>
  );
};

export default ClientLayout;
