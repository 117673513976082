import React from "react";
import Page from "../../component/Page";
import TestResult from "../component/TestResult/TestResult";

const TestResultPage = () => {
    return (
        <>
            <Page title="Go the Extra Mile | Test Page">
                <TestResult />
            </Page>
        </>
    )
}
export default TestResultPage;