import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import "./ui.scss";

const OmnibusBanner = ({ title, content, xs_col, col, color }) => {

    return (<>
        {/* <Grid item xs={xs_col} lg={col}> */}
        <Box>
            <Typography variant="subtitle1" sx={{ color: color, textAlign: { xs: "center", md: "left" } }}  >{title}</Typography>
            {content}
        </Box>
        {/* </Grid> */}
    </>)
}
export default OmnibusBanner;