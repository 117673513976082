import React from "react";
import Page from "../../component/Page";
import Dashboard from "../component/Dashboard/dashboard";

const DashboardPage = () => {
    return (<>
        <Page title="Go the Extra Mile | Dashboard">
            <Dashboard />
        </Page>
    </>)
}
export default DashboardPage