import React, { useContext } from "react";
import { Box, Typography, List, Link, ListItem } from "@mui/material";
import { IntlContext } from "src/App";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    root: {
        background: "#2E2E2E",
        padding: "80px 0 58px",
        "@media (max-width:1199px )": {
            padding: "60px 0 48px",
        },
        "& .MuiTypography-root": {
            color: "#ffffff"
        },
        "& .faq_subtitle": {
            fontSize: "22px",
            lineHeight: "27px",
            paddingTop: "37px",
            "@media (max-width:1199px )": {
                fontSize: "20px",
                lineHeight: "24px",
                paddingTop: "30px",
            },
            "@media (max-width:899px )": {
                fontSize: "18px",
                lineHeight: "20px",
                paddingTop: "20px",
            },
            "@media (max-width:599px )": {
                fontSize: "16px",
                lineHeight: "18px",
                paddingTop: "15px",
            },
        }
    },
    root_: {
        margin: "30px 0 50px",
        "& .title": {
            fontSize: "26px",
            fontWeight: "700",
            marginBottom: "16px"
        },
        "& .sales_terms": {
            fontSize: "14px",
            fontWeight: "500",
            marginBottom: "10px",
            lineHeight: "24px",
            letterSpacing: "0.5px"
        },
        "& .MuiListItem-root": {
            display: "list-item",
            padding: "0px 0px 0px 4px",
            "& .sales_terms": {
                lineHeight: "22px"
            },
        },
    }
}))

const TermsConditions = () => {
    const classes = useStyles();
    const intlContext = useContext(IntlContext);
    const gothe_mile = intlContext?.messages;

    return (
        <>
            <Box className="main_container">
                <Box sx={{ mt: "40px" }}>
                    <Typography className="my_cart_title">{gothe_mile?.terms_conditions?.title}</Typography>
                </Box>
                <Box className={classes.root_}>
                    <Typography className="title">{gothe_mile?.terms_conditions?.subtitle}</Typography>
                    <Typography className="sales_terms">In these Sales Terms and Conditions (Sales Terms) ”we”, “us” or “our” means Quiz Time In Critical Care Pty Ltd (ACN 614 368 973), its successors and assignees. You, the person, organisation or entity that purchases products or related services from us is referred to as “you” or “your”. (Collectively “the Parties”). These Sales Terms apply to all sales made by us to you. These Terms are available at <Link target="_blank" href="https://gotheextramile.com/" className="email_link"> www.gotheextramile.com </Link> (Site).</Typography>
                    <Typography className="sales_terms">These Sales Terms form the agreement under which we will supply products and related services to you. Please read these Sales Terms carefully. If you have any questions, please contact us using the contact details below, before you purchase products or related services from us.</Typography>
                    <Typography className="sales_terms">You accept our Sales Terms by making a purchase from us. Your purchase from us indicates that you have had sufficient opportunity to access these Sales Terms and contact us, that you have read, accepted and will comply with these Terms, and that you are 18 years or older, or have the consent of a legal guardian who is 18 years or older. You must not order products or services from us if you are under 18 years of age or do not have the consent of a legal guardian who is 18 years or older. If you do not agree to these Sales Terms, you should not purchase from us.</Typography>
                    <Typography className="sales_terms">Our Terms of Use set out the terms and conditions for visiting and using the Site. Our Privacy Policy sets out how we collect, use and protect your personal information. These are available on the Site.</Typography>
                    <List sx={{ listStyle: "decimal", p: "0 0 0 40px" }}>
                        <ListItem>
                            <Typography className="sales_terms">Registration: In order to access and make use of the Site you may be required to create an account using an email address and password (Account). Before your first use of the Account you will be required to create a username. You must not create and/or maintain more than one Account on the Site. Basic information is required when creating an Account. You agree to provide accurate, current and complete information during the registration process and to update such information to keep it accurate, current and complete. Furthermore, it is your responsibility to keep your Account details confidential. You are liable for all activity on your Account. You acknowledge that you will not disclose your Account details to any third party, and that you will take sole responsibility for any activities or actions under your Account, whether or not those activities or actions have been authorised by you. You must immediately notify us of any unauthorised use of your Account.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Products, Services and Orders:</Typography>
                            <Typography className="sales_terms">(a) We offer for sale and free of charge multiple choice medical tests for doctors and nurses. All tests are sold or made available on either a limited-time and/or limited number of attempts basis and are only accessible for a set period of time and/or a limited number of attempts after purchase, as set out on the Site. If you do not make use of a test during this time, you will be prohibited from using the test thereafter and will have to repurchase or re-access it should you wish to make further use of it. All tests are to be used for personal non-commercial purposes only.</Typography>
                            <Typography className="sales_terms">(b) You may order from us as set out on the Site. We may at our discretion accept or reject an order depending on factors including our ability to validate payment for the products or services.</Typography>
                            <Typography className="sales_terms">(c) It is your responsibility to check and confirm with us the order details, including product/service and pricing, before you complete your order on the Site.</Typography>
                            <Typography className="sales_terms">(d) We will provide you with order details, which may include an order number, an order ID, the shipping and billing addresses and a description of what was ordered, when you order and pay on the Site and your payment has been validated.</Typography>
                            <Typography className="sales_terms">(e) A binding agreement comes into existence between you and us once we confirm your order and receipt of payment. No changes to these Sales Terms will be effective unless we both agree to the changes in writing.</Typography>
                            <Typography className="sales_terms">(f) Please be aware that some of the products sold through the Site may not be suitable for children under 18 years of age, so please take extra care when purchasing products for children under 18 years of age.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Price and Payments:</Typography>
                            <Typography className="sales_terms">(a) You agree to pay the purchase price specified on the Site at the time that you place your order for the purchase of a product/service. All amounts are stated in Australian dollars. All purchase prices include Australian GST (where applicable).</Typography>
                            <Typography className="sales_terms">(b) You must pay for the product or services by one of the methods set out on the Site. Your payment will be processed upon receipt of your order. You must not pay, or attempt to pay, for an order through any fraudulent or unlawful means. If your payment is not able to be successfully processed then your order may be cancelled.</Typography>
                            <Typography className="sales_terms">(c) If you make a payment by way of credit card, you warrant that the information provided to us is true, accurate and complete, that you are authorised to use the credit card to make the payment, that the payment will be honoured by the card issuer, and that you will maintain sufficient funds in the account to cover the purchase price.</Typography>
                            <Typography className="sales_terms">(d) Nothing prevents us from taking any action necessary to recover unpaid fees. If you fail to pay, your information will be passed on for collection and/or legal action. You acknowledge and agree that you are liable for and will pay all costs including debt collection, commission, solicitor’s fees and any out of pocket expenses. If you at any time exceed our payment terms and as a result are passed on for collection and/or legal action, we may place a default against you with a credit reporting agency.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Availability and Cancellation:</Typography>
                            <Typography className="sales_terms">(a) All purchases made with us are subject to availability. We do our best to ensure that products and related services supplied by us are made readily available to you, and to keep the Site up to date with availability of products or services.</Typography>
                            <Typography className="sales_terms">(b) If there is a considerable delay in supplying products or services to you, or if for any reason we cannot supply a product or service you have ordered, we will contact you using the contact details provided by you when you placed the order. You can choose a refund or credit.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Delivery:</Typography>
                            <Typography className="sales_terms">(a) Location: Normally, we deliver worldwide. Please refer to the delivery information on the Site to check that you are in our delivery area. If you are not in our delivery area please contact us to discuss delivery options.</Typography>
                            <Typography className="sales_terms">(b) Cost: We offer free delivery for all products, as set out on the Site.</Typography>
                            <Typography className="sales_terms">(c) Method: We will generally deliver the products to you instantaneously via email or a link made available to you on the Site.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Discount Codes and Promotions: We may from time to time offer promotional discount codes, which may be applicable to products or services on the Site, and must be entered at the time of submitting your order. The conditions of use relating to any discount code will be specified at the time that it is issued.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Intellectual Property Rights</Typography>
                            <Typography className="sales_terms">(a) Intellectual Property Rights mean all present and future rights in or to any patent, copyright, database rights, registered design or other design right, utility model, trade mark (whether registered or not and including any rights in get up or trade dress), brand name, service mark, trade name, eligible layout right, chip topography right and any other rights of a proprietary nature in or to the results of intellectual activity in the industrial, commercial, scientific, literary or artistic fields, whether registrable or not, including all renewals, extensions and revivals of, and all rights to apply for, any of the foregoing rights (Intellectual Property Rights).</Typography>
                            <Typography className="sales_terms">(b) We own all Intellectual Property Rights in the Site, business, products, services, tests and branding, as between us and you. The products, including tests, contain material which is owned by or licensed to us and is protected by Australian and international laws (Materials). We own the copyright which subsists in all creative and literary works incorporated into our Materials.</Typography>
                            <Typography className="sales_terms">(c) You must not breach our Intellectual Property Rights by, including but not limited to altering or modifying any of the Materials, creating derivative works from the Materials, distributing our materials to other parties, or using our Materials for commercial purposes such as on-sale to third parties.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Dispute: Your feedback is important to us. We seek to resolve your concerns quickly and effectively. If you have any feedback or questions about our products or services, please contact us. If there is a dispute between the Parties in relation to these Sale Terms, the Parties agree to the following dispute resolution procedure:</Typography>
                            <Typography className="sales_terms"> (a) The complainant must tell the respondent in writing the nature of the dispute, what outcome the complainant wants and what action the complainant thinks will settle the dispute. The Parties agree to meet in good faith to seek to resolve the dispute by agreement between them (Initial Meeting).</Typography>
                            <Typography className="sales_terms">(b) If the Parties cannot agree how to resolve the dispute at the Initial Meeting, any Party may refer the matter to a mediator. If the Parties cannot agree on who the mediator should be, the complainant will ask the Law Society of New South Wales to appoint a mediator. The mediator will decide the time and place for mediation. The Parties must attend the mediation in good faith, to seek to resolve the dispute.</Typography>
                            <Typography className="sales_terms">(c) Any attempts made by the Parties to resolve a dispute pursuant to this clause are without prejudice to other rights or entitlements of the Parties under these Terms, by law or in equity.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Consumer Law, Return, Refund and Exchange Policy</Typography>
                            <Typography className="sales_terms">(a) ACL: Seller’s goods and services come with consumer guarantees that cannot be excluded under the Australian Consumer Law in the Consumer and Competition Act 2010 (ACL). Nothing in the Sales Terms limits or excludes any guarantees, warranties, representations or conditions implied or imposed by law, including the ACL or any liability under the ACL, which may not be limited or excluded.</Typography>
                            <Typography className="sales_terms">(b) Goods & Services: If you are a consumer as defined in the ACL, the following notice applies to you: "Our goods and services come with warranties and guarantees that cannot be excluded under the Australian Consumer Law (Consumer Guarantees). You are entitled to a replacement or a refund for a major failure and for compensation for any other reasonably foreseeable loss or damage. You are also entitled to have goods repaired or replaced if the goods fail to be of acceptable quality and the failure does not amount to a major failure. You are also entitled to have services remedied if they are not rendered with due care and skill or they are not fit for purpose and the failure does not amount to a major failure”.</Typography>
                            <Typography className="sales_terms">(c) Repair, replacement or a refund: If you wish to seek repair, resupply, replacement or a refund for a product or service, please contact us and we will explain the requirements to you. This may include you providing proof of purchase and evidence of the faulty product to us.</Typography>
                            <Typography className="sales_terms">(d) Refund: If you are entitled to a refund, we will only give you the refund once evidence of faulty product is received by us and we have been provided with a reasonable opportunity to inspect the product and assessed whether it is eligible for a refund under these Sales Terms. Any refund we make will be by the same payment method used to purchase the product or service.
                            </Typography>
                            <Typography className="sales_terms">(e) Change of mind: We do not accept returns for change of mind or circumstances including but not limited to unsuitability of tests.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Limitation of Liability and Disclaimers:</Typography>
                            <Typography className="sales_terms">(a) While the information and material contained on the Site and in our products is believed to be valuable, accurate and current, it is provided by us in good faith on an "as is" basis, and we and our directors, officers, employees, contractors and agents accept no responsibility for and make no representations or warranties to you or to any other person as to the reliability, suitability, effectiveness, accuracy or completeness of the information contained on the Site or in our products. We do not warrant or represent that our products will produce a benefit to you, professional or otherwise. Medicine is an ever-changing field. Some of the information on the Site and our materials may already be obsolete.</Typography>
                            <Typography className="sales_terms">(b) Certain legislation including the ACL and similar consumer protection laws and regulations may confer you with rights, warranties, guarantees and remedies relating to the provision of goods or services to you by us via the Site which cannot be excluded, restricted or modified. Our liability is governed solely by the ACL and these Terms.</Typography>
                            <Typography className="sales_terms">(c) To the extent permitted by law, we exclude all conditions and warranties, except for your Rights including but not limited to:</Typography>
                            <List sx={{ listStyle: "decimal", p: "0 0 0 40px" }}>
                                <ListItem>
                                    <Typography className="sales_terms">we expressly disclaim any implied or express guarantees, representations or conditions of any kind, which are not stated in these Sales Terms;</Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography className="sales_terms">we take no responsibility for, and will not be liable for the Site or the products or services being unavailable; and</Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography className="sales_terms">we will not be liable for any loss, damage, injury, death, costs including legal costs, or expense whether direct, indirect, incidental, special, consequential and/or incidental, including loss of profits, revenue, production, opportunity, access to markets, goodwill, reputation, use or any indirect, remote, abnormal or unforeseeable loss, or any loss or damage relating to business interruption, loss of programs or other data on your information systems or costs of replacement goods, or otherwise, suffered by you or claims made against you, arising out of or in connection with the Site, inability to access or use the Site, the products, the services, the late supply of products or services, or these Sales Terms, even if we were expressly advised of the likelihood of such loss or damage.</Typography>
                                </ListItem>
                            </List>
                            <Typography className="sales_terms">(d) To the extent permitted by law, our total liability arising out of or in connection with the products, the services or these Sales Terms, however arising, including under contract, tort (including negligence), in equity, under statute or otherwise, will not exceed the total price paid by you for the purchase of products and/or services under these Terms.</Typography>
                            <Typography className="sales_terms">This clause will survive termination of these Terms.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Amendment: These Sales Terms may be amended from time to time without prior notice. Your purchase from us following any such amendments will be deemed to be confirmation that you accept those amendments. We recommend that you check the current Sales Terms before purchase. Our agents, employees and third parties do not have authority to change these Sales Terms.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Indemnity: You indemnify us from and against all claims, suits, demands, actions, liabilities, costs and expenses (including legal costs and expenses on a full indemnity basis) resulting from your breach of these Terms. You agree to co-operate with us (at your own expense) in the handling of disputes, complaints, investigations or litigation that arise as a result of your use of our products or services including but not limited to disputes, complaints, investigations or litigation that arises out of or relates to incorrect information you have given us. The obligations under this clause will survive termination of these Terms.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">General:</Typography>
                            <Typography className="sales_terms">(a) Privacy: We agree to comply with the legal requirements of the Australian Privacy Principles as set out in the Privacy Act 1988 (Cth) and any other applicable legislation or privacy guidelines</Typography>
                            <Typography className="sales_terms">(b) Accuracy: While we endeavour to keep the information up to date and correct, we make no representations, warranties or guarantees, express or implied, about the completeness, accuracy, reliability, suitability or availability of any information, images, products, services, or related graphics contained on the Site for any particular purpose. You hereby acknowledge that such information and materials may contain mistakes, inaccuracies or errors and we expressly exclude any liability for such to the fullest extent permissible by law</Typography>
                            <Typography className="sales_terms">(c) Termination: We reserve the right to refuse supply of the products or services ordered by you, terminate your account, terminate our contract with you, and remove or edit content on the Site at our sole discretion, without incurring any liability to you.</Typography>
                            <Typography className="sales_terms">(d) GST: If and when applicable, GST payable on our services or products will be set out in our invoices. By accepting these Sales Terms you agree to pay us an amount equivalent to the GST imposed on these charges.</Typography>
                            <Typography className="sales_terms">(e) Relationship of Parties: These Sales Terms are not intended to create a relationship between the Parties of partnership, joint venture, or employer-employee.</Typography>
                            <Typography className="sales_terms">(f) Force Majeure: We will not be liable for any delay or failure to perform our obligations under these Sales Terms if such delay is due to any circumstance beyond our reasonable control. If we are delayed from performing our obligations due to such a circumstance for a period of at least 2 months, we may terminate our agreement with you by giving you 5 business days' notice in writing.</Typography> <Typography className="sales_terms">(g) Notice: Any notice in connection with these Sales Terms will be deemed to have been duly given when made in writing and delivered or sent by email, facsimile or post to the Party to whom such notice is intended to be given or to such other address, email address or facsimile number as may from time to time be notified in writing to the other Party.</Typography>
                            <Typography className="sales_terms">(h) Waiver: Any failure by a Party to insist upon strict performance by the other of any provision in these Sales Terms will not be taken to be a waiver of any existing or future rights in relation to the provision. No waiver by us of any of these Sales Terms shall be effective unless we expressly state it is a waiver and we communicate to you in writing.</Typography>
                            <Typography className="sales_terms">(i) Assignment: You must not assign any rights and obligations under these Terms, whether in whole or in part, without our prior written consent.</Typography>
                            <Typography className="sales_terms">(j) Severability: If any of these Sales Terms are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.</Typography>
                            <Typography className="sales_terms">(k) Jurisdiction and Applicable Law: Your use of the Site and any dispute arising out of your use of it is subject to the laws of New South Wales and the Commonwealth of Australia. These Sales Terms are governed by the laws of New South Wales and the Commonwealth of Australia and subject to the exclusive jurisdiction of the courts operating in New South Wales. The Site may be accessed throughout Australia and overseas. We make no representation that the Site complies with the laws (including intellectual property laws) of any country outside Australia. If you access the Site from outside Australia, you do so at your own risk and are responsible for complying with the laws in the place where you access the Site.</Typography>
                            <Typography className="sales_terms">(l) Entire Agreement: These Sales Terms and any document expressly referred to in them represent the entire agreement between you and us and supersede any prior agreement, understanding or arrangement between you and us, whether oral or in writing.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">For any questions or notice, please contact us at:</Typography>
                            <Typography className="sales_terms">Quiz Time In Critical Care Pty Ltd ACN 614 368 973<br />
                                <Link href="mailto:sumesharora1@gotheextramile.com" className="email_link">sumesharora1@gotheextramile.com
                                </Link>
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms">Last update: 22 September 2016</Typography>
                            <Typography className="sales_terms">LegalVision ILP Pty Ltd owns the copyright in this document and use without permission is prohibited.</Typography>
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    )
}
export default TermsConditions;