import React, { useContext, useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { IntlContext } from "src/App";
import { createStyles, makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import { useSelector } from "react-redux";
import { fetchFromStorage, saveToStorage } from "src/helpers/context/Storage";
import PreLoader from "src/component/Loader";
import MessageBox from "../../component/UI/MessageBox";
import OtpInput from 'react-otp-input';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        "& .MuiTypography-root": {
            marginBottom: "50px",
            '@media (max-width:899px)': {
                marginBottom: "30px",
            },
        },
        "& .input_box_wraper": {
            width: "200px",
            margin: "0 auto 40px",
            "& .input_box": {
                width: "30px",
                "& .MuiOutlinedInput-root": {
                    paddingLeft: "0px",
                    border: "0px",
                    borderBottom: "1px solid #373737",
                    borderRadius: "0px",
                    "& .MuiOutlinedInput-input": {
                        padding: "0px",
                        textAlign: "center"
                    },
                },
            },
        },
        "& .MuiButton-root": {
            fontSize: "20px",
            height: "50px",
            maxWidth: "160px",
            width: "100%",
            '@media (max-width:1199px)': {
                fontSize: "18px",
                lineHeight: "16px",
                height: "45px",
            },
            '@media (max-width:899px)': {
                fontSize: "16px",
                lineHeight: "14px",
                padding: "12px",
                height: "40px",
            },
            '@media (max-width:599px)': {
                fontSize: "13px",
                height: "35px",
                lineHeight: "16px",
            },
        },
        "& .resend_otp": {
            color: "#405CFB",
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: "500",
            textTransform: "capitalize",
            padding: "0px",
            height: "30px",
            "&:hover": {
                backgroundColor: "#fff",
            },
            "@media (max-width:599px)": {
                fontSize: "14px",
            },
        },
    }
}))

const VerifyOTP = ({ closeOtpPopup, setOpenPopup, setPopup }) => {
    const classes = useStyles();
    const intlContext = useContext(IntlContext);
    const gothe_mile = intlContext?.messages;
    const redux_auth = useSelector((state => state.ActionReducer.userDetails));
    const user = redux_auth ? redux_auth : fetchFromStorage("userDetails");
    const [loader, setLoader] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [validOtpError, setValidOtpError] = useState(false);
    const [resendOTP, setResendOTP] = useState(false);

    const closePopup = () => {
        setSuccessMsg(false);
        setOpenPopup(false);
        setPopup(false);
    };

    const resendOtp = async () => {
        let payload = {
            _id: user?.data?._id,
        };
        setLoader(true);
        try {
            const { data, status } = await axiosInstance.post(URLS?.resendOTP, payload);
            if (status === 200 && data?.value === true) {
                setValidOtpError(false);
                setLoader(false);
                setResendOTP(true);
            } else { setLoader(false); }
        } catch (error) {
            setLoader(false);
        }
    };

    return (
        <>
            {loader && <PreLoader />}
            <MessageBox open={successMsg} handleClose={closePopup}
                content={
                    <>
                        <Typography>{gothe_mile?.accountSuccess}</Typography>
                        <Button variant="blue" onClick={closePopup} sx={{ mt: { xs: "30px", md: "40px" }, height: "30px", fontSize: "16px", minWidth: "80px" }}>{gothe_mile?.ok}</Button>
                    </>
                } />
            <Box className={classes.root}>
                <Typography>{gothe_mile?.enter_otp}</Typography>
                <Formik
                    initialValues={{
                        first: "", second: "", third: "", fourth: ""
                    }}
                    validationSchema={Yup.object().shape({
                        first: Yup.string().required("Please Enter OTP").min(4, "Must be 4 Digits required"),
                    })}
                    onSubmit={async (data) => {
                        let payload = {
                            name: user?.data?.name,
                            firstName: user?.data?.name,
                            lastName: user?.data?.name,
                            email: user?.data?.email,
                            password: user?.data?.password,
                            mobile: user?.data?.mobile,
                            country_code: user?.data?.country_code,
                            otp: data?.first,
                            _id: user?.data?._id,
                        };
                        setLoader(true);
                        try {
                            const { data, status } = await axiosInstance.post(URLS.verifyUserWithOtp, payload)
                            if (status === 200) {
                                setResendOTP(false);
                                if (data?.value === true) {
                                    setLoader(false);
                                    setValidOtpError(false);
                                    let payload = {
                                        email: user?.data?.email
                                    };
                                    setLoader(true);
                                    try {
                                        const { data, status } = await axiosInstance.post(URLS.welcomeEmail, payload);
                                        if (status === 200) {
                                            setSuccessMsg(true);
                                            let payload = {
                                                accessLevel: user?.data?.accessLevel,
                                                email: user?.data?.email,
                                                name: user?.data?.name,
                                                firstName: user?.data?.name,
                                                lastName: user?.data?.name,
                                                mobile: user?.data?.mobile,
                                                photo: user?.data?.photo,
                                                _id: user?.data?._id,
                                            }
                                            try {
                                                const { data, status } = await axiosInstance.post(URLS.setUser, payload);
                                                if (status === 200) {
                                                    if (data?.value === true) {
                                                        setLoader(false);
                                                        saveToStorage("authdata", data);
                                                        // dispatch(loginUser(data));
                                                    } else {
                                                        setLoader(false);
                                                    }
                                                } else {
                                                    setLoader(false);
                                                }
                                            }
                                            catch (error) { setLoader(false); }
                                        } else {
                                            setLoader(false);
                                        }
                                    }
                                    catch (error) { setLoader(false); }
                                } else {
                                    setValidOtpError(true); setLoader(false);
                                }
                            } else {
                                setLoader(false);
                            }
                        }
                        catch (error) { setLoader(false); }
                    }}
                >{({
                    errors, handleBlur, handleChange, handleSubmit, values, touched, setFieldValue
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box className="input_box_wraper">
                            <Box className="input_box_otp_wrapper">
                                <OtpInput
                                    name="first"
                                    className="input-box-otp"
                                    value={values?.first}
                                    onChange={(e) => { setFieldValue("first", e) }}
                                    numInputs={4}
                                    isInputNum
                                    containerStyle="input_box_otp_wrapper"
                                />
                            </Box>
                            <Box mt={1}>
                                <span className="text-danger">{touched.first && errors.first}</span>
                            </Box>
                        </Box>
                        <Grid container spacing={{ xs: 2, ms: 3 }} sx={{ justifyContent: "center" }}>
                            <Grid item xs={5} sm={4}>
                                <Button type="submit" variant="browse_blue">{gothe_mile?.verify_otp}</Button>
                            </Grid>
                            <Grid item xs={5} sm={4}>
                                <Button variant="outline_btn" onClick={closeOtpPopup}>{gothe_mile?.cancel}</Button>
                            </Grid>
                        </Grid>
                        {validOtpError && <Typography sx={{ pt: "20px", mb: "0px!important", color: "#a94442" }}>{gothe_mile?.message?.invalidOTP}</Typography>}
                        <Box sx={{ mt: "30px" }}>
                            <Button className="resend_otp" onClick={resendOtp}>{gothe_mile?.resend_otp}</Button>
                        </Box>
                        {resendOTP && <Typography sx={{ color: "#248F17", m: "20px 0 0 0!important", fontSize: "16px" }}>{gothe_mile?.message?.requestedNewOTP}</Typography>}
                    </form>
                )}
                </Formik>
            </Box>
        </>)
}
export default VerifyOTP;