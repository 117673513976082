import React, { useContext, useEffect, useState } from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import { URLS } from "src/helpers/constants/url";
import axiosInstance from "src/helpers/context/axiosInstance";
import PreLoader from "src/component/Loader";
import { IntlContext } from "src/App";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context/Storage";

const useStyles = makeStyles((theme) => createStyles({
    root: {
        minHeight: "450px",
        margin: "47px 0 35px",
        "& .search_container": {
            "@media (max-width:1199px)": {
                width: "899px",
            },
            "@media (max-width:899px)": {
                // width: "599px",
                width: "100%",
            },
            "@media (max-width:599px)": {
                padding: "0 40px"
            },
        },
        "& .MuiTypography-root": {
            marginBottom: "18px",
            fontSize: "32px",
            fontWeight: "600",
            lineHeight: "42px",
            "@media (max-width:1199px)": {
                fontSize: "30px",
                lineHeight: "40px",
            },
            "@media (max-width:899px)": {
                fontSize: "28px",
                lineHeight: "38px",
            },
            "@media (max-width:599px)": {
                fontSize: "26px",
                lineHeight: "36px",
            },
        },
        "& .MuiList-root": {
            listStyle: "disc",
            color: "#405CFB",
            padding: "0px",
            paddingLeft: "18px",
            marginBottom: "5px",
            "& .MuiListItem-root": {
                padding: "0px",
                display: "list-item",
                padding: "7px 0",
                "& a": {
                    // padding: "5px 10px",
                    display: "block",
                    fontSize: "18px",
                    color: "#405CFB",
                    lineHeight: "22px",
                    "@media (max-width:599px)": {
                        fontSize: "16px",
                        lineHeight: "20px",
                    },
                    "&:hover": {
                        color: "#4AB5FC"
                    }
                },
            },
        },
    }
}))

const Search = () => {
    const classes = useStyles();
    const intlContext = useContext(IntlContext);
    const gothe_mile = intlContext?.messages;
    const redux_text = useSelector((state) => state?.ActionReducer?.searchText);
    const searchedText = redux_text ? redux_text : fetchFromStorage("searchText");
    const [categoryData, setCategoryData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [text, setText] = useState(searchedText);

    const getSearchResult = async () => {
        const payload = {
            textSearch: text
        };
        setLoader(true);
        try {
            const { data, status } = await axiosInstance.post(URLS?.globalSearch, payload);
            if (status === 200) {
                setLoader(false);
                setCategoryData(data?.data);
            } else {
                setLoader(false);
            }
        } catch (error) { setLoader(false); }
    };

    useEffect(() => {
        setText(searchedText);
    }, [searchedText]);

    useEffect(() => {
        if (searchedText === text) {
            getSearchResult();
        }
    }, [text]);

    const _getStyledText = (item) => {
        var re = new RegExp(text, 'i');
        return (
            item?.replace(re, "<b>" + text + "</b>")
        )
    };

    return (
        <>
            {loader && <PreLoader />}
            <Box className={classes.root}>
                <Box className="sub_container search_container">
                    <Typography>{gothe_mile?.search_results}</Typography>

                    {categoryData?.category?.length === 0 && categoryData?.category2?.length === 0 && categoryData?.category3?.length === 0 && categoryData?.product?.length === 0 &&
                        <Box sx={{ textAlign: "center", p: "10px 0" }}>
                            <Typography>{gothe_mile?.noSearch_data}</Typography>
                        </Box>
                    }

                    {categoryData?.category &&
                        <List sx={{ listStyle: "disc" }}>
                            {categoryData?.category?.map((item, id) => {
                                return (<>
                                    <ListItem key={id}>
                                        <NavLink to={"/category/" + item?.myslug} dangerouslySetInnerHTML={{ __html: _getStyledText(item?.name) }} />
                                    </ListItem>
                                </>)
                            })}
                        </List>
                    }

                    {categoryData?.category2 &&
                        <List>
                            {categoryData?.category2?.map((item, id) => {
                                return (
                                    <>
                                        {item?.category &&
                                            item?.category?.map((item1) => {
                                                return (
                                                    <>
                                                        <ListItem key={id}>
                                                            <NavLink to={"/category/" + item1?.myslug + "/" + item?.myslug} dangerouslySetInnerHTML={{ __html: _getStyledText(item1?.name) + " - " + _getStyledText(item?.name) }} />
                                                        </ListItem>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                )
                            })}
                        </List>
                    }
                    {categoryData?.category3 &&
                        <List>
                            {categoryData?.category3?.map((item, id) => {
                                return (
                                    <>
                                        {item?.categoryLevel2 &&
                                            item?.categoryLevel2?.map((item1) => {
                                                return (<>
                                                    {item1?.category &&
                                                        item1?.category?.map((item2) => {
                                                            return (<>
                                                                <ListItem key={id}>
                                                                    <HashLink smooth to={"/category/" + item2?.myslug + "/" + item1?.myslug + "#" + item?.myslug} dangerouslySetInnerHTML={{ __html: _getStyledText(item2?.name) + " - " + _getStyledText(item1?.name) + " - " + _getStyledText(item?.name) }} />
                                                                </ListItem>
                                                            </>)
                                                        })
                                                    }
                                                </>)
                                            })
                                        }
                                    </>
                                )
                            })}
                        </List>
                    }
                    {categoryData?.product &&
                        <List>
                            {categoryData?.product?.map((item, id) => {
                                return (
                                    <>
                                        {item?.categoryLevel3 &&
                                            item?.categoryLevel3?.map((item1) => {
                                                return (<>
                                                    {item1?.categoryLevel2 &&
                                                        item1?.categoryLevel2?.map((item2) => {
                                                            return (<>
                                                                {item2?.category &&
                                                                    item2?.category?.map((item3) => {
                                                                        return (
                                                                            <>
                                                                                <ListItem key={id}>
                                                                                    <HashLink smooth to={"/category/" + item3?.myslug + "/" + item2?.myslug + "#" + item1?.myslug} dangerouslySetInnerHTML={{ __html: _getStyledText(item?.name) }} />
                                                                                </ListItem>
                                                                            </>)
                                                                    })}
                                                            </>)
                                                        })
                                                    }
                                                </>)
                                            })
                                        }
                                    </>
                                )
                            })}
                        </List>
                    }
                </Box>
            </Box>
        </>
    )
}
export default Search;