import React from "react";

// LAYOUTS
import ClientLayout from "./layouts/ClientLayout";
import HomePage from "./view/Pages/Home";
import CategoryPage from "./view/Pages/Category";
import CategoryLevel2Page from "./view/Pages/CategoryLevel2";
import CartPage from "./view/Pages/Cart";
import UserProfilePage from "./view/Pages/UserProfile";
import FaqPage from "./view/Pages/FAQ";
import DashboardPage from "./view/Pages/Dashboard";
import TermsConditionsPage from "./view/Pages/TermsConditions";
import PrivacyPolicyPage from "./view/Pages/PrivacyPolicy";
import TermsOfUsePage from "./view/Pages/TermsOfUse";
import Searchpage from "./view/Pages/Search";
import FreeTestpage from "./view/Pages/FreeTest";
import TestFormPage from "./view/Pages/TestForm";
import PageNotFoundPage from "./view/Pages/404Page";
import ThankYouPage from "./view/Pages/ThankYouPage";
import TestResultPage from "./view/Pages/TestResult";

export const routes = [
  {
    path: "/",
    element: <ClientLayout />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/search/:textSearch", element: <Searchpage /> },
      { path: "/category/:slug", element: <CategoryPage /> },
      { path: "/category/:slug/:slug2", element: <CategoryLevel2Page /> },
      { path: "/cart", element: <CartPage /> },
      { path: "/faq", element: <FaqPage /> },
      { path: "/termsconditions", element: <TermsConditionsPage /> },
      { path: "/privacypolicy", element: <PrivacyPolicyPage /> },
      { path: "/termsofuse", element: <TermsOfUsePage /> },
      { path: "/testfree", element: <FreeTestpage /> },
      { path: "/test-form/:formId/:id", element: <TestFormPage /> },
      { path: "/testresult", element: <TestResultPage /> },
      { path: "*", element: <PageNotFoundPage /> },
    ],
  },
];

export const userRoutes = [
  {
    path: "/",
    element: <ClientLayout />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/search/:textSearch", element: <Searchpage /> },
      { path: "/userprofile", element: <UserProfilePage /> },
      { path: "/dashboard", element: <DashboardPage /> },
      { path: "/category/:slug", element: <CategoryPage /> },
      { path: "/category/:slug/:slug2", element: <CategoryLevel2Page /> },
      { path: "/cart", element: <CartPage /> },
      { path: "/faq", element: <FaqPage /> },
      { path: "/termsconditions", element: <TermsConditionsPage /> },
      { path: "/privacypolicy", element: <PrivacyPolicyPage /> },
      { path: "/termsofuse", element: <TermsOfUsePage /> },
      { path: "/testfree", element: <FreeTestpage /> },
      { path: "/test-form/:formId/:id", element: <TestFormPage /> },
      { path: "/testresult", element: <TestResultPage /> },
      { path: "/thankyou", element: <ThankYouPage /> },
      { path: "*", element: <PageNotFoundPage /> },
    ],
  },
];
