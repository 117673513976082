export default {
  h1: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 50,
    fontWeight: 700,
    lineHeight: "60px",
    "@media (max-width:1535px)": {
      fontSize: 42,
      lineHeight: "50px",
    },
    "@media (max-width:1199px)": {
      fontSize: 32,
      lineHeight: "40px",
    },
    "@media (max-width:899px)": {
      fontSize: 22,
      lineHeight: "30px",
    },
    "@media (max-width:599px)": {
      fontSize: 20,
      lineHeight: "26px",
    },
  },
  h2: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 40,
    fontWeight: 700,
    lineHeight: "49px",
    "@media (max-width:1535px)": {
      fontSize: 36,
      lineHeight: "44px",
    },
    "@media (max-width:1199px)": {
      fontSize: 32,
      lineHeight: "40px",
    },
    "@media (max-width:899px)": {
      fontSize: 28,
      lineHeight: "34px",
    },
    "@media (max-width:599px)": {
      fontSize: 24,
      lineHeight: "28px",
    },
  },
  h3: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 38,
    fontWeight: 600,
    lineHeight: "47px",
    "@media (max-width:1535px)": {
      fontSize: 35,
      lineHeight: "44px",
    },
    "@media (max-width:1199px)": {
      fontSize: 32,
      lineHeight: "40px",
    },
    "@media (max-width:899px)": {
      fontSize: 30,
      lineHeight: "36px",
    },
    "@media (max-width:599px)": {
      fontSize: 28,
      lineHeight: "30px",
    },
  },
  h4: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "29px",
    "@media (max-width:1535px)": {
      fontSize: 22,
      lineHeight: "26px",
    },
    "@media (max-width:1199px)": {
      fontSize: 20,
      lineHeight: "24px",
    },
    "@media (max-width:899px)": {
      fontSize: 18,
      lineHeight: "20px",
    },
    "@media (max-width:599px)": {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  h5: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "26px",
    "@media (max-width:899px)": {
      fontSize: 14,
      lineHeight: "22px",
    },
    "@media (max-width:599px)": {
      fontSize: 12,
      lineHeight: "24px",
    },
  },
  h6: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 16,
    fontWeight: 300,
    lineHeight: "19px",
    "@media (max-width:899px)": {
      fontSize: 14,
      lineHeight: "16px",
    },
    "@media (max-width:599px)": {
      fontSize: 12,
      lineHeight: "14px",
    },
  },
  subtitle1: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 56,
    fontWeight: 700,
    lineHeight: "68px",
    letterSpacing: 0,
    color: "#373737",
    "@media (max-width:1536px)": {
      fontSize: 48,
      lineHeight: "52px",
    },
    "@media (max-width:1199px)": {
      fontSize: 36,
      lineHeight: "42px",
    },
    "@media (max-width:899px)": {
      fontSize: 24,
      lineHeight: "32px",
    },
    "@media (max-width:599px)": {
      fontSize: 22,
      lineHeight: "30px",
    },
  },
  subtitle2: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 28,
    fontWeight: 600,
    lineHeight: "34px",
    letterSpacing: 0,
    color: "#373737",
    "@media (max-width:1535px)": {
      fontSize: 25,
      lineHeight: "30px",
    },
    "@media (max-width:1199px)": {
      fontSize: 22,
      lineHeight: "26px",
    },
    "@media (max-width:899px)": {
      fontSize: 18,
      lineHeight: "22px",
    },
    "@media (max-width:599px)": {
      fontSize: 16,
      lineHeight: "24px",
    },
  },
  body1: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "27px",
    letterSpacing: 0,
    color: "#101010",
    "@media (max-width:1199px)": {
      lineHeight: "25px",
    },
    "@media (max-width:899px)": {
      fontSize: 14,
      lineHeight: "22px",
    },
    "@media (max-width:599px)": {
      lineHeight: "20px",
    },
  },
  body2: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 18,
    lineHeight: "30px",
    fontWeight: 400,
    letterSpacing: 0,
    color: "#373737",
    "@media (max-width:1199px)": {
      fontSize: 16,
      lineHeight: "26px",
    },
    "@media (max-width:899px)": {
      fontSize: 14,
      lineHeight: "24px",
    },
    "@media (max-width:599px)": {
      fontSize: 12,
      lineHeight: "22px",
    },
  },
};
