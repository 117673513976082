import React from "react";
import Page from "../../component/Page";
import TermsOfUse from "../component/TermsOfUse/TermsOfUse";

const TermsOfUsePage = () => {
    return (
        <>
            <Page title="Go the Extra Mile | Terms Of Use">
                <TermsOfUse />
            </Page>
        </>
    )
}
export default TermsOfUsePage;