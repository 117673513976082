import React, { useEffect, useState } from "react";
import { Box, Typography, Link } from "@mui/material";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import { Config } from "src/helpers/context/config";

const SubscripionOption = ({ gothe_mile, setLoader }) => {
    const [categoryList, setCategoryList] = useState([]);

    const categoryColor = ['bg-blue', 'bg-green', 'bg-yellow', 'bg-purple', 'bg-blue', 'bg-green', 'bg-yellow', 'bg-purple', 'bg-blue'];

    const getCategoryName = async () => {
        setLoader(true);
        try {
            const { data, status } = await axiosInstance.post(URLS.getCategoryList);
            if (status === 200) {
                setLoader(false);
                setCategoryList(data?.data?.results);
            } else { setLoader(false); }
        } catch (error) { setLoader(false); }
    };

    useEffect(() => {
        getCategoryName();
    }, []);

    return (
        <>
            <Box id="subscription" className="subscription-wrap sm-title">
                <Box className="main_container">
                    <Typography variant="h2" className="sub-title">{gothe_mile?.home?.subscription_option}</Typography>
                    <Box className="subscription-box-wrap">
                        {categoryList?.map((list, index) => {
                            let lastDigit = index % 10;
                            return (
                                <>
                                    <Link underline="none" className={`subscripion-box ${categoryColor[lastDigit]}`} href={`/category/` + list?.myslug}>
                                        <Box sx={{ width: "95px", height: "95px", borderRadius: "50%" }}>
                                            <img src={Config?.mediaURL + "=" + list?.imageThumb} alt={list?.name} style={{
                                                borderRadius: "50%", width: "100%", height: "100%", objectFit: "cover"
                                            }} />
                                        </Box>
                                        <Typography variant="h3">{list?.name}</Typography>
                                        <Typography dangerouslySetInnerHTML={{ __html: list?.description }} />
                                    </Link>
                                </>
                            )
                        })}
                    </Box>
                </Box>
            </Box >
        </>
    )
};
export default React.memo(SubscripionOption);