import React, { createContext } from "react";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import { routes, userRoutes } from "./routes";
import "../src/assets/scss/global.scss";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme/index";
import messagesEn from "../src/helpers/en.json";
import { fetchFromStorage } from "./helpers/context/Storage";

const Context = createContext();

const App = () => {
  const redux_auth = useSelector((state) => state?.ActionReducer?.authdata);
  const auth = redux_auth ? redux_auth : fetchFromStorage("authdata");

  let routeData = auth ? userRoutes : routes;
  const routing = useRoutes(routeData);

  const messages = { ...messagesEn };

  return (
    <ThemeProvider theme={theme}>
      <Context.Provider value={{ messages }}>{routing}</Context.Provider>
    </ThemeProvider>
  );
};

export default App;
export { Context as IntlContext };
