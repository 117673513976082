import React, { useContext } from "react";
import { Box, Typography, Link, List, ListItem } from "@mui/material";
import { IntlContext } from "src/App";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    root: {
        background: "#2E2E2E",
        padding: "80px 0 58px",
        "@media (max-width:1199px )": {
            padding: "60px 0 48px",
        },
        "& .MuiTypography-root": {
            color: "#ffffff"
        },
        "& .faq_subtitle": {
            fontSize: "22px",
            lineHeight: "27px",
            paddingTop: "37px",
            "@media (max-width:1199px )": {
                fontSize: "20px",
                lineHeight: "24px",
                paddingTop: "30px",
            },
            "@media (max-width:899px )": {
                fontSize: "18px",
                lineHeight: "20px",
                paddingTop: "20px",
            },
            "@media (max-width:599px )": {
                fontSize: "16px",
                lineHeight: "18px",
                paddingTop: "15px",
            },
        }
    },
    root_: {
        margin: "30px 0 50px",
        "& .title": {
            fontSize: "26px",
            fontWeight: "700",
            marginBottom: "16px"
        },
        "& .sales_terms": {
            fontSize: "14px",
            fontWeight: "500",
            marginBottom: "10px",
            lineHeight: "22px",
            letterSpacing: "0.5px"
        },
        "& .MuiListItem-root": {
            display: "list-item",
            padding: "0px 0px 0px 4px",
            "& .sales_terms": {
                lineHeight: "20px",
            },
        },
    },
}))

const PrivacyPolicy = () => {
    const classes = useStyles();
    const intlContext = useContext(IntlContext);
    const gothe_mile = intlContext?.messages;
    return (
        <>
            <Box className="main_container">
                <Box sx={{ mt: "40px" }}>
                    <Typography className="my_cart_title">{gothe_mile?.privacy_policy?.title}</Typography>
                </Box>
                <Box className={classes.root_}>
                    <Typography className="title">{gothe_mile?.privacy_policy?.subtitle}</Typography>
                    <Typography className="sales_terms">This Privacy Policy sets out Quiz Time in Critical Care Pty Ltd (ACN 614 368 973), its successors and assignees (referred to as <strong>we, our</strong> or <strong>us</strong>) commitment to protecting the privacy of your personal information that we collect through this website <Link target="_blank" href="https://gotheextramile.com/" className="email_link"> www.gotheextramile.com </Link>(Site) or directly from you, being the person, organisation or entity that uses our Site (referred to as <strong>you</strong> or <strong>your</strong>).</Typography>
                    <Typography className="sales_terms">Please read this Privacy Policy carefully. Please contact us if you have any questions - our contact details are at the end of this Privacy Policy.</Typography>
                    <Typography className="sales_terms">You providing us with personal information indicates that you have had sufficient opportunity to access this Privacy Policy and that you have read and consented to it.</Typography>
                    <Typography className="sales_terms">If you do not wish to provide personal information to us, then you do not have to do so, however it may affect your use of this Site or any products and services offered on it.</Typography>
                    <List sx={{ listStyle: "decimal", p: "0 0 0 40px" }}>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Collection of personal information</strong></Typography>
                            <Typography className="sales_terms"><strong>Personal information:</strong> The type of information we collect may include:
                            </Typography>
                            <List sx={{ listStyle: "disc", p: "0 0 0 40px" }}>
                                <ListItem><Typography className="sales_terms">name;</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">contact details including email address, address and telephone number;</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">credit card information if necessary;</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">demographic information such as postcode; and</Typography>
                                </ListItem>
                                <ListItem><Typography className="sales_terms">any other information requested on this Site or otherwise requested by us or provided by you.</Typography></ListItem>
                            </List>
                            <Typography className="sales_terms"><strong>Your use of our Site: As with most online businesses, we may log information about your access and use of our Site, including through the use of Internet cookies, your communications with our Site, the type of browser you are using, the type of operating system you are using and the domain name of your Internet service provider.</strong></Typography>
                            <Typography className="sales_terms"><strong>Your opinion and feedback:</strong> We may contact you to voluntarily respond to questionnaires, surveys or market research to seek your opinion and feedback. Providing this information is optional to you.</Typography>
                            <Typography className="sales_terms">If we receive your personal information from third parties, we will protect it as set out in this Privacy Policy.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Collection and use of personal information</strong></Typography>
                            <Typography className="sales_terms">We collect and use the information for purposes including:</Typography>
                            <List sx={{ listStyle: "disc", p: "0 0 0 40px" }}>
                                <ListItem><Typography className="sales_terms">to contact and communicate with you;</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">for internal record keeping;</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">for market research and business development including website development;</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">for marketing including direct marketing;</Typography>
                                </ListItem>
                                <ListItem><Typography className="sales_terms">to run competitions or offer additional benefits to you; and</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">to send you promotional information about third parties that we think may be of interest to you; and</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">for data analytics purposes.</Typography></ListItem>
                            </List>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Disclosure of personal information to third parties</strong></Typography>
                            <Typography className="sales_terms">We may disclose personal information to:</Typography>
                            <List sx={{ listStyle: "disc", p: "0 0 0 40px" }}>
                                <ListItem><Typography className="sales_terms">credit reporting agencies and courts, tribunals and regulatory authorities where customers fail to pay for goods or services provided by us to them;</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">courts, tribunals, regulatory authorities and law enforcement officers as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">third parties, including agents or sub-contractors, who assist us in providing information, products, services or direct marketing to you. This may include parties located, or that store data, outside of Australia; and</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">for marketing including direct marketing;</Typography>
                                </ListItem>
                                <ListItem><Typography className="sales_terms">third parties to collect and process data including Google Analytics or other relevant business. This may include parties that store data outside of Australia.
                                </Typography></ListItem>
                                <ListItem><Typography className="sales_terms">to send you promotional information about third parties that we think may be of interest to you; and</Typography></ListItem>
                                <ListItem><Typography className="sales_terms">for data analytics purposes.</Typography></ListItem>
                            </List>
                            <Typography className="sales_terms"><strong>Where we disclose your personal information to third parties for these purposes, we will request that the third party follow this Privacy Policy regarding handling of your personal information.</strong></Typography>
                            <Typography className="sales_terms">If there is a change of control of our business or a sale or transfer of business assets, we reserve the right to transfer to the extent permissible by law our user databases, together with any personal information and non-personal information contained in those databases. This information may be disclosed to a potential purchaser. We would seek to only disclose information in good faith.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Your rights and controlling your personal information</strong></Typography>
                            <Typography className="sales_terms"><strong>Choice and consent:</strong> Providing us with your personal information is optional to you. You can choose not to provide personal information. When you provide us with your personal information, you consent to the terms in this Privacy Policy, and to us disclosing or receiving your personal information for these purposes.</Typography>
                            <Typography className="sales_terms"><strong>Your provision of third party information:</strong> If you provide us with third party personal information then you warrant to us that you have the third party’s consent to provide this.</Typography>
                            <Typography className="sales_terms"><strong>Restrict:</strong> You may choose to restrict the collection or use of your personal information. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us at the email address listed in this Privacy Policy.</Typography>
                            <Typography className="sales_terms"><strong>Access:</strong> You may request details of personal information that we hold about you, in certain circumstances set out in the Privacy Act 1988 (Cth). An administrative fee may be payable for the provision of information. We may refuse to provide you with information that we hold about you, in certain circumstances set out in the Privacy Act.</Typography>
                            <Typography className="sales_terms"><strong>Correction:</strong> If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us by email. We rely in part upon customers advising us when their personal information changes. We will respond to any request within a reasonable time. We will endeavour to promptly correct any information found to be inaccurate, incomplete or out of date.</Typography>
                            <Typography className="sales_terms"><strong>Complaints:</strong> If you believe that we have breached the Australian Privacy Principles and wish to make a complaint about that breach, please contact us by email setting out details of the breach. We will promptly investigate your complaint and respond to you in writing setting out the outcome of our investigation, what steps we propose to take to remedy the breach and any other action we will take to deal with your complaint.</Typography>
                            <Typography className="sales_terms">Unsubscribe: To unsubscribe from our e-mail database, or opt out of communications, please contact us using the details below</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Storage and Security</strong></Typography>
                            <Typography className="sales_terms">We are committed to ensuring that the information you provide is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.</Typography>
                            <Typography className="sales_terms">No information transmitted over the Internet can be guaranteed to be secure. We cannot guarantee the security of any information that you transmit to us, or receive from us. The transmission and exchange of information is carried out at your own risk. Although we take measures to safeguard against unauthorised disclosures of information, we cannot assure you that personal information that we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Cookies & Web Beacons</strong></Typography>
                            <Typography className="sales_terms">We may use cookies on this Site from time to time. Cookies are text files placed in your computer's browser to store your preferences. Cookies, by themselves, do not tell us your e-mail address or other personally identifiable information. However, once you choose to furnish the Site with personally identifiable information, this information may be linked to the data stored in the cookie.</Typography>
                            <Typography className="sales_terms">We may use web beacons on this Site from time to time. Web beacons or clear.gifs are small pieces of code placed on a web page to monitor the visitors’ behaviour and collect data about the visitors viewing a web page. For example, web beacons can be used to count the users who visit a web page or to deliver a cookie to the browser of a visitor viewing that page.</Typography>
                            <Typography className="sales_terms">We may use Google Analytics or another analytics provider to collect and process data. To find out how Google uses data when you use third party websites or applications, please see www.google.com/policies/privacy/partners/ or any other URL Google may use from time to time.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Links to other websites</strong></Typography>
                            <Typography className="sales_terms">Our Site may contain links to other websites of interest. We do not have any control over those websites. We are not responsible for or liable for the protection and privacy of any information which you provide whilst visiting such websites, and such websites are not governed by this Privacy Policy.</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className="sales_terms"><strong>Amendments</strong></Typography>
                            <Typography className="sales_terms">This Privacy Policy may be amended, including with changes, additions and deletions, from time to time in our sole discretion. Your continued use of our Site following any amendments indicates that you accept the amendments. You should check this Privacy Policy regularly, prior to providing personal information, to ensure you are aware of any changes, and only proceed to provide personal information if you accept the new Privacy Policy.</Typography>
                        </ListItem>
                    </List>
                    <Typography className="sales_terms"><strong>For any questions or notice, please contact our Privacy Officer at:</strong></Typography>
                    <Typography className="sales_terms">Quiz Time in Critical Care Pty Ltd ACN 614 368 973 <br /><strong>Email: <Link href="mailto:sumesharora1@gotheextramile.com" className="email_link">sumesharora1@gotheextramile.com
                    </Link></strong></Typography>
                    <Typography className="sales_terms"><strong>Last update: 2 September 2016</strong><br />LegalVision ILP Pty Ltd owns the copyright in this document and use without permission is prohibited.</Typography>
                </Box>
            </Box>
        </>
    )
}
export default PrivacyPolicy;