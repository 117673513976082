import React from "react";
import Page from "../../component/Page";
import Faq from "../component/FAQ/Faq";

const FaqPage = () => {
    return (
        <>
            <Page title="Go the Extra Mile | FAQ">
                <Faq />
            </Page>
        </>
    )
}
export default FaqPage;