import React, { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { IntlContext } from "../../App";

const useStyles = makeStyles((theme) => createStyles({
    root: {
        padding: "100px 0",
        textAlign: "center",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "fit-content",
        margin: "0 auto",
        "& .MuiTypography-root": {
            margin: "10px 0"
        },
        "& .gradient_text": {
            marginBottom: "30px",
            fontSize: "60px",
            lineHeight: "50px",
            background: "-webkit-linear-gradient(90deg,rgba(74, 181, 252, 1) 0%,rgba(64, 92, 251, 1) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            "@media (max-width:899px )": {
                marginBottom: "25px",
                fontSize: "50px",
                lineHeight: "40px",
            },
            "@media (max-width:599px )": {
                marginBottom: "20px",
                fontSize: "40px",
                lineHeight: "30px",
            },
        },
        "& .MuiButton-root": {
            minWidth: "220px",
            width: "100%",
            marginTop: "50px",
            "@media (max-width:1199px )": {
                minWidth: "200px",
                height: "40px",
                marginTop: "40px",
            },
            "@media (max-width:899px )": {
                minWidth: "180px",
                height: "40px",
                marginTop: "30px",
            },
            "@media (max-width:599px )": {
                minWidth: "150px",
                height: "40px",
                marginTop: "20px",
            },
        },
    },
}));

const PageNotFound = () => {
    const classes = useStyles();
    const intlContext = useContext(IntlContext);
    const gothe_mile = intlContext?.messages;

    return (
        <>
            <Box className="main_container">
                <Box className={classes.root}>
                    <Typography variant="h2" className="gradient_text">{gothe_mile?.pageNotFound_Error}</Typography>
                    <Typography variant="h5">{gothe_mile?.pageNotFound}</Typography>
                    <Typography variant="h5">{gothe_mile?.pageNotExist}</Typography>
                    <Box>
                        <Button variant="apply_btn" href="/">{gothe_mile?.goToHome}</Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
export default PageNotFound;