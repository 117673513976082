import React from "react";
import Logo from "./../assets/images/icons/i_logo.svg";

const AppLogo = () => {
  return (
    <>
      <img src={Logo} alt="logo" />
    </>
  )
};

export default AppLogo;