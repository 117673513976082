import React from "react";
import Page from "../../component/Page";
import CategoryLevel2 from "../component/CategoryLevel2/CategoryLevel2";

const CategoryLevel2Page = () => {
    return (
        <>
            <Page title="Go the Extra Mile | orientation">
                <CategoryLevel2 />
            </Page>
        </>
    )
};

export default CategoryLevel2Page;