import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as Whatapp } from "src/assets/images/icons/WhatsApp.svg";
import { ReactComponent as WhatappArrow } from "src/assets/images/icons/wp-arrow.svg";
import WhatappCode from "src/assets/images/waQRcode.png";

const WhatappContact = ({ gothe_mile }) => {
    return (
        <>
            <Box className="wp-wrap sm-title">
                <Box className="main_container">
                    <Box className="wp-inner">
                        <Box className="wp-box">
                            <Box className="wp-text-wrap">
                                <a href="https://api.whatsapp.com/send?phone=61402090219" target="_blank">
                                    <span className="text-badge">{gothe_mile?.home?.text_only}</span>
                                    <Box className="wp-content-wrap">
                                        <Box className="wp-text">
                                            <Whatapp />
                                            <span>{gothe_mile?.home?.WhatsApp}</span>
                                        </Box>
                                        <Box className="wp-number">
                                            <span>{gothe_mile?.wpPhoneNumber}</span>
                                        </Box>
                                    </Box>
                                </a>
                            </Box>
                        </Box>
                        <Box className="wp-scan">
                            <Box className="scan-text">
                                <span>{gothe_mile?.home?.scan_QR}<br></br>{gothe_mile?.home?.code_ask}<br></br>{gothe_mile?.home?.question}</span>
                            </Box>
                            <Box className="scan-arrow"><WhatappArrow /></Box>
                            <Box className="scan-code">
                                <img src={WhatappCode} alt="Whatapp Scan Code" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
};
export default React.memo(WhatappContact);