import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

const ReadMoreAbout = ({ children }) => {
    const text = children;
    const [isReadMoreAbout, setIsReadMoreAbout] = useState(true);
    const toggleReadMoreAbout = () => {
        setIsReadMoreAbout(!isReadMoreAbout);
    };

    return (
        <Box sx={{ pt: "24px" }}>
            {isReadMoreAbout ? text?.slice(0, 1) : text}
            <span onClick={() => toggleReadMoreAbout()} className="read-or-hide">
                {text?.length > 1 ? (isReadMoreAbout ? "read more" : "show less") : ""}
            </span>
        </Box>
    );
};

const AboutUs = ({ gothe_mile }) => {

    return (
        <>
            <Box id="aboutus" className="aboutus-wrap sm-title">
                <Box className="main_container">
                    <Typography variant="h2" className="sub-title">
                        {gothe_mile?.home?.about_us}
                    </Typography>
                    <Box sx={{ p: "0 27px" }}>
                        <Typography>
                            GO THE EXTRA MILE (GTEM) is an online platform for Multiple Choice Questions <b>(MCQ)</b> in intensive care medicine. GTEM App is called ‘Critical Care MCQ’ by Go the Extra Mile; and is available on Apple App Store and Google Play Store.
                        </Typography>
                        <ReadMoreAbout>
                            <Typography style={{ display: "inline", marginRight: "6px" }}>
                                <b>ORGANIZATION -</b> GTEM has over <b>2500 unique questions</b>. The questions are organized in the form of <b>TESTS</b> (e.g. Ventilator Waveforms Part I). A collection of tests make a <b>CHAPTERS</b>(e.g. Respiratory Medicine). Several chapters are compiled to create an <b>OMNIBUS</b> (e.g. EDIC Omnibus).
                            </Typography>
                            <Typography>
                                <b>Test Styles - Exam Style tests</b> 30 questions/test. The answers are provided at the end of test. For example, in EDIC Omnibus - All tests, except mock exams, have MCQ presented like this. 30-60 minutes allowed for each test.
                            </Typography>
                            <Typography sx={{ pt: "0px!important" }}>
                                <b>Learn-X style tests</b> 10-12 MCQ per test. Answer immediately after the question, and then again at the end of the test. 960 minutes allowed for each test – So one may resume later if interrupted while doing a test.
                            </Typography>
                            <Typography>
                                <b>Features</b>
                                <ul style={{ margin: "0px" }}>
                                    <li>Online tests (Website or App)</li>
                                    <li>Tests are timed</li>
                                    <li>Instant marking upon completion</li>
                                    <li>Detailed feedback</li>
                                    <li>Email results (Automatic)</li>
                                    <li>View all previous scores in dashboard</li>
                                    <li>Study Groups (T&C apply)</li>
                                    <li>No ads</li>
                                    <li>One year subscription (Maximum 3 attempts per test). No auto-renewal</li>
                                </ul>
                            </Typography>
                            <Typography>
                                <b>EDIC Omnibus </b>
                                <ul style={{ margin: "0px" }}>
                                    <li>Contains all unique questions available on Go the Extra Mile </li>
                                    <li>6 EDIC Mock Exams</li>
                                    <li>Tests have 30 questions each</li>
                                    <li>Answers and detailed feedback <b>at the end</b> of the test</li>
                                    <li>EDIC Omnibus + Learn-X provides access to both</li>
                                </ul>
                            </Typography>
                            <Typography sx={{ pb: { xs: "18px", md: "24px" } }}>
                                <b>Learn-X</b>
                                <ul style={{ margin: "0px" }}>
                                    <li>It contains all unique questions on Go the Extra Mile (Same questions as EDIC Omnibus, just organized in different style tests) </li>
                                    <li>10-12 questions per test</li>
                                    <li>Answer and feedback immediately after <b>every question</b> and then again at the end of the test</li>
                                    <li>For MCQ practice in short bursts – each test takes 5-15 minutes</li>
                                    <li>EDIC Omnibus + Learn-X provides access to both</li>
                                </ul>
                            </Typography>
                            <Typography style={{ display: "inline", marginRight: "6px" }}>
                                <b>Go The Extra Mile for Nurses</b> - Intensive Care, as a speciality, wouldn’t exist if not for the hard-working and learned nurses. The nurses spend the most time with the patient and are often the first ones to pick up subtle changes in their clinical status. Go The Extra Mile offers questions for the nurses working in intensive care. Many of these questions focus on the interpretation of monitor waveforms (e.g., ventilator, ECG and pacing, capnogram, pulmonary artery catheter or central venous pressure waveform, etc.) or the setup of complex systems like continuous renal replacement therapy. Upon passing the test, a certificate of completion will be emailed. <b>The certificate states the approximate time required to complete each test, including the review of feedback.</b>
                            </Typography>
                        </ReadMoreAbout>
                    </Box>
                </Box>
            </Box>
        </>
    )
};
export default React.memo(AboutUs);