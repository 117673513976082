import React from "react";
import Page from "../../component/Page";
import Subscription from "../component/ThankYou";


const ThankYouPage = () => {
    return (
        <>
            <Page title="Go the Extra Mile | Airway Management">
                <Subscription />
            </Page>
        </>
    )
};

export default ThankYouPage;