import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rating from "@mui/material/Rating";
import { Config } from "src/helpers/context/config";
import Profile from "src/assets/images/profile.png";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <Typography
      className="text"
      sx={{ minHeight: { xs: "200px", sm: "196px", md: "180px", lg: "132px" } }}
    >
      {isReadMore ? text?.slice(0, 240) : text}
      <span
        style={{ marginLeft: "5px" }}
        onClick={() => toggleReadMore()}
        className="read-or-hide"
      >
        {text?.length > 240 ? (isReadMore ? "read more" : "show less") : ""}
      </span>
    </Typography>
  );
};

const testimonial = {
  className: "slider variable-width",
  arrows: true,
  dots: true,
  // infinite: false,
  slidesToShow: 2.5,
  slidesToScroll: 1,
  variableWidth: true,
  speed: 1000,
  // autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        variableWidth: false,
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 991,
      settings: {
        variableWidth: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
      },
    },
  ],
};

const Testimonial = ({ gothe_mile, setLoader }) => {
  const [issize, setIssize] = useState("");
  const [testimonials, setTestimonials] = useState([]);

  const functionName = () => {
    const width = window.screen.width;
    if (width < 1200) {
      setIssize(false);
    } else {
      setIssize(true);
    }
  };
  window.addEventListener("resize", functionName);

  const getTestimonial = useCallback(async () => {
    let payload = {
      page: "",
      keyword: "",
    };
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.post(
        URLS.getTestimonial,
        payload
      );
      if (status === 200) {
        setLoader(false);
        const filterTestimonilasByorder = data?.data?.results?.sort(
          (a, b) => a?.order - b?.order
        );
        setTestimonials(filterTestimonilasByorder);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    functionName();
    getTestimonial();
  }, []);

  return (
    <>
      <Box id="testimonials" className="testimonial-wrap sm-title">
        <Box className="main_container">
          <Typography variant="h2" className="sub-title">
            {gothe_mile?.home?.testimonials}
          </Typography>
          <Box className="testimonial-slider-wrap">
            <Slider {...testimonial}>
              {testimonials?.map((item, id) => {
                return (
                  <>
                    <Box
                      key={id}
                      className="testimonial-content"
                      style={{ width: issize ? 410 : "" }}
                    >
                      <Box className="inner-t">
                        <Box
                          sx={{ width: "82px", height: "82px", m: "0 auto" }}
                        >
                          <img
                            src={
                              item?.image
                                ? Config?.mediaURL + "=" + item?.image
                                : Profile
                            }
                            alt="testimonial-user"
                          />
                        </Box>
                        <span className="testimonial-user">{item?.name}</span>
                        <Rating
                          name="half-rating-read"
                          defaultValue={item?.ratting}
                          precision={0.5}
                          readOnly
                        />
                        <ReadMore>{item?.content}</ReadMore>
                      </Box>
                    </Box>
                  </>
                );
              })}
            </Slider>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default React.memo(Testimonial);
