import React, { useContext } from "react";
import { Box, Typography, Button } from "@mui/material";
import { IntlContext } from "src/App";
import { createStyles, makeStyles } from "@mui/styles";
import { ReactComponent as CheckMark } from "src/assets/images/icons/TCheckmark.svg";

const useStyles = makeStyles((theme) => createStyles({
    root: {
        margin: "75px auto",
        maxWidth: "438px",
        width: "100%",
        backgroundColor: "#F2F2F2",
        borderRadius: "5px",
        padding: "22px 24px 24px",
        textAlign: "center",
        "& .title": {
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "29px",
            color: "#41A6E9",
            padding: "13px 0 7px",
        },
        "& .content": {
            lineHeight: "19px",
            color: "#373737"
        },
        "& .MuiButton-root": {
            width: "100%",
            marginTop: "13px",
            padding: "12px 20px",
            height: "46px",
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: "600",
            "@media (max-width:899px)": {
                height: "40px",
                padding: "10px 20px",
                fontSize: "16px",
                lineHeight: "20px",
            },
            "@media (max-width:599px)": {
                width: "70%",
                height: "38px",
                padding: "8px 20px",
                fontSize: "14px",
                lineHeight: "18px",
            },
        },
    },
}))

const Subscription = () => {
    const classes = useStyles();
    const intlContext = useContext(IntlContext);
    const gothe_mile = intlContext?.messages;

    return (
        <>
            <Box className="sub_container">
                <Box className={classes.root}>
                    <CheckMark />
                    <Typography variant="body1" className="title">{gothe_mile?.subscription?.title}</Typography>
                    <Typography variant="body1" className="content">{gothe_mile?.subscription?.checkSubscription}</Typography>
                    <Button variant="blue" href="/dashboard">{gothe_mile?.subscription?.dashboard}</Button>
                </Box>
            </Box>
        </>
    )
};
export default Subscription; 