import React from "react";
import Page from "../../component/Page";
import PageNotFound from "../component/404Page";

const PageNotFoundPage = () => {
    return (
        <>
            <Page title="Go the Extra Mile | PageNotFound">
                <PageNotFound />
            </Page>
        </>
    )
};

export default PageNotFoundPage;