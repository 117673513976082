import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { IntlContext } from 'src/App';
import TabsComponent from '../UI/IconTabs';
import GeneralInfo from './GeneralInfo';
import ChangePassword from './ChangePassword';
import DeactiveAccount from './DeactiveAccount';
import DialogBox from 'src/view/component/UI/DialogBox';
import { ReactComponent as BlackgenInfo } from 'src/assets/images/icons/bGenInfo.svg';
import { ReactComponent as Blacksetting } from 'src/assets/images/icons/bSetting.svg';
import { ReactComponent as BlackDeactive } from 'src/assets/images/icons/bDeactive.svg';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context/Storage';

const UserProfile = () => {
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const redux_auth = useSelector((state) => state.ActionReducer.authdata);
  const auth = redux_auth ? redux_auth : fetchFromStorage('authdata');
  const [istoggle, setistoggle] = useState(null);
  const [value, setValue] = useState(0);
  const tabItems = [
    {
      icon: <BlackgenInfo />,
      label: 'General Information',
      content: <GeneralInfo auth={auth} />,
    },
    {
      icon: <Blacksetting />,
      label: 'Change Password',
      content: <ChangePassword auth={auth} />,
    },
    {
      icon: <BlackDeactive />,
      label: 'Deactivate account',
      content: (
        <DialogBox
          open={istoggle}
          content={
            <DeactiveAccount
              close={() => {
                setistoggle(false);
                setValue(0);
              }}
            />
          }
          className={'modal-deactive'}
        />
      ),
      isDelete: true,
    },
  ];

  useEffect(() => {
    setistoggle(true);
  }, [value]);

  return (
    <>
      <Box className="main_container">
        <Box className="cart_block_wraper">
          <Typography className="my_cart_title">
            {gothe_mile?.my_settings}
          </Typography>
          <TabsComponent
            tabs={tabItems}
            value={value}
            handleChangeTab={(data) => {
              setValue(data);
            }}
          />
        </Box>
      </Box>
    </>
  );
};
export default UserProfile;
