import React from "react";
import { Link, Typography } from "@mui/material";
import { ReactComponent as CirclePlusIcon } from "src/assets/images/icons/feather-plus-circle.svg";
import AccordionComp from "../UI/Accordian";

const SubscriptionQuery = () => {
  // const Acc_summery = () => {
  //     return (<>
  //         <Typography>I am a trainee in Intensive Care Medicine. How can I use Go the Extra Mile to prepare for my primary exam?</Typography>
  //     </>)
  // }

  // const Acc_content = () => {
  //     return (<>
  //         <Typography>
  //             At Go the Extra Mile you can test your knowledge of intensive care in the form of multiple choice questions. The site contains questions for both physicians and nurses. Doctors may use this site for preparing for the exams (e.g. European Diploma in Intensive Care, FFICM (UK), DM Intensive Care (India), or CICM (Australia). The site contains questions for both the primary exam and final fellowship exam. The supervisors of training may use the orientation packages for introducing the speciality to the new recruits. Cardiothoracic and neurointensive care collections may be utilised for acclimatisation to the speciality ICUs.
  //         </Typography>
  //         <Typography>
  //             The intensive care nurses may use the site for orientation. The nurse educators may assign the orientation packs to the new nurses. A certificate of completion may be emailed to both the nurse and the supervisor upon successful completion of the assignment
  //         </Typography>
  //         <Typography>
  //             Experienced nurses may use the advanced nursing tests to evaluate their proficiency.
  //         </Typography>
  //     </>)
  // }

  const acc_summery = [
    {
      icon: <CirclePlusIcon />,
      summery: <Typography>What are the subscription options?</Typography>,
      content: (
        <>
          <Typography>
            When you subscribe to GTEM, you can do online tests for a period of
            one year. The popular subscription options are as follows. All the
            following are <strong>one year subscription</strong>. Every test may
            be taken up to a maximum <strong>three times</strong> during the
            duration of subscription.
          </Typography>
          <ul>
            <li>
              <strong>EDIC Omnibus </strong>– Contains nearly all MCQ on GTEM.
              Exam style tests (See FAQ 1 above).
            </li>
            <li>
              <strong>Learn-X </strong>– Contains nearly all MCQ on GTEM.
              Learn-x Style questions (See FAQ 1 above).
            </li>
            <li>
              <strong>EDIC Omnibus + Learn-X </strong>– Contains all MCQ
              available on GTEM in both formats – Exam style and Learn-X style
              (See FAQ 1).
            </li>
            <li>
              <strong>Primary Omnibus </strong>– For primary exam in Intensive
              Care – It is a work in progress and does not cover the full scope
              of Critical Care Primary curriculum.
            </li>
            <li>
              <strong>Anaesthetic Primary </strong>– For primary exam in
              Anaesthetics. It is a work in progress and does not cover the full
              scope of the Anaesthetic Primary Curriculum.
            </li>
          </ul>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          What is the difference between Omnibus and Chapter?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            a) CHAPTER – A collection of tests for a given topic. For example,
            ECMO – contains only the tests related to ECMO.
          </Typography>
          <Typography>
            b) Omnibus – A collection of tests for specific exam(s). For
            example,
            <ul className="ul-lower-alpha">
              <li>
                EDIC Omnibus has majority of the questions available at GTEM
              </li>
              <li>Crit Care Curriculum Omnibus </li>
              <li>Anaesthetic Primary</li>
            </ul>
          </Typography>

          {/* <Typography>
            For institutional orders, discounted price may be available. Please
            email{" "}
            <Link
              href="mailto:support@gotheextramile.com"
              className="email_link"
            >
              support@gotheextramile.com
            </Link>{" "}
            for discount offers
          </Typography> */}
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          If I subscribe to an omnibus or a test on GTEM, do I own the
          questions?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            No. The questions and the explanation provided remain the property
            of GTEM. Subscription to a package allows you to attempt the tests
            online. At the end of your subscription, or once you have exhausted
            the allowed number of attempts, you will not be able to access the
            tests. Subscription to a chapter or an omnibus provides you access
            for 12 months. The questions remain the property of GTEM.
          </Typography>
          <Typography>
            The explanations provided for the detailed review of the tests
            should not be used as a substitute for standard reading material
            like textbooks.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          I did not finish my tests before the subscription expired. Can I get
          an extension on my subscription?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            No. GTEM is unable to extend the subscription. It is important that
            the prospective trainees time their subscription thoughtfully. For
            example, if the exam is 13 months away, it may be appropriate to
            wait for subscription until the exam is less than 12 months later.
            This avoids premature loss of access to the tests.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>How can I make payment to subscribe on GTEM?</Typography>
      ),
      content: (
        <>
          <Typography>
            Access to the tests is available only after the payment has been
            accepted. Payment to GTEM may be made in one of the following ways –
          </Typography>
          <Typography>
            a) Stripe – Stripe is an online payment gateway for payment using a
            credit card or a debit card. You don’t necessarily need to have a
            Stripe account. Your card details are securely handled by the
            Stripe. GTEM cannot access to your card details when you use Stripe.{" "}
          </Typography>
          <Typography>
            b) Critical Care MCQ App on Android or iOS – As an in-app purchase.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>Does GTEM provide institutional subscriptions?</Typography>
      ),
      content: (
        <>
          <Typography>
            Yes. The institutional subscriptions are discounted.
          </Typography>
          <Typography>
            Please email{" "}
            <Link
              href="mailto:support@gotheextramile.com"
              className="email_link"
            >
              support@gotheextramile.com
            </Link>{" "}
            for details
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>
          I am a nurse educator in my ICU. How can I use GTEM for orientation of
          new nurses in ICU?
        </Typography>
      ),
      content: (
        <>
          <Typography>
            You may use the services at GTEM for both orientation of the new
            recruits or for CME (Continuing Medical Education). Under ‘Nursing’,
            you will find several tests, which will help manage your Continuing
            Medical Education. Each test has 30 questions. The pass marks are
            set at 70%. Each test may be taken 3 times. Upon passing the test, a
            certificate of completion will be emailed. Each test should take
            approximately 45 minutes (Factoring possible multiple attempts to
            get passing marks), and this shall be stated on the certificate. The
            certificates may be used as evidence of completion of tests.
          </Typography>
          <Typography>
            For institutional orders, discounted price may be available. Please
            email{" "}
            <Link
              href="mailto:support@gotheextramile.com"
              className="email_link"
            >
              support@gotheextramile.com
            </Link>{" "}
            for discount offers.
          </Typography>
        </>
      ),
    },
    {
      icon: <CirclePlusIcon />,
      summery: (
        <Typography>Why does GTEM not provide all MCQ for free?</Typography>
      ),
      content: (
        <>
          <Typography>
            GTEM is run by a Sydney based intensivist. GTEM went live in 2017.
            100% of the profit, since its start, have been donated to charity.
            GTEM is a Silver Level ‘Champion for Children’ for UNICEF and
            ‘Proudly Supports’ Médecins Sans Frontières (Doctors Without
            Borders). In addition to the above, GTEM supports several local and
            international charities every year.
          </Typography>
          <Typography>
            GTEM does not have any advertisements. Running GTEM is expensive,
            and its only sources of revenue are the subscription fee. GTEM keeps
            its running cost low by the following – It has no dedicated office
            space and no employees. It is a one person undertaking – the
            director is also the responsible for writing questions, reviewing
            invited tests, study groups, social media and everything else that
            comes with the territory. GTEM director does not any salary or
            remuneration for his time and work towards GTEM (The work is its own
            reward).
          </Typography>
        </>
      ),
    },
  ];

  return (
    <>
      <AccordionComp acc_data={acc_summery} />
    </>
  );
};
export default SubscriptionQuery;
