export default {
    MuiButton: {
        variants: [
            {
                props: { variant: 'blue' },
                style: {
                    background: "linear-gradient(90deg, #4AB5FC 0%, #405CFB 100%)",
                    color: "#ffffff",
                    padding: " 18px 21.2px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: '20px',
                    lineHeight: "24px",
                    borderRadius: "10px",
                    textTransform: 'none',
                    letterSpacing: "0px",
                    transition: "all 2s",
                    flex: "auto",
                    "&:hover": {
                        background: "linear-gradient(270deg,rgba(74, 181, 252, 1) 0%, rgba(64, 92, 251, 1) 100% )",
                    },
                    '@media (max-width:1199px)': {
                        fontSize: "18px",
                        lineHeight: "22px",
                        padding: "16px 15px",
                    },
                    '@media (max-width:899px)': {
                        fontSize: "16px",
                        lineHeight: "20px",
                        padding: "14px 12px",
                    },
                    '@media (max-width:599px)': {
                        fontSize: "14px",
                        lineHeight: "18px",
                        padding: "12px",
                    },
                },

            },
            {
                props: { variant: 'skyblue' },
                style: {
                    fontSize: '20px',
                    fontFamily: "'Montserrat', sans-serif",
                    textTransform: 'none',
                    backgroundColor: "#41A6E9",
                    color: "#ffffff",
                    borderRadius: "6px",
                    lineHeight: "24px",
                    padding: "7px 11px",
                    letterSpacing: "0px",
                    transition: "all 2s",
                    "&:hover": {
                        background: "#ffffff",
                        color: "#41A6E9",
                    },
                    '@media (max-width:1199px)': {
                        fontSize: "18px",
                        lineHeight: "22px",
                        padding: "5px 12px",
                    },
                    '@media (max-width:899px)': {
                        fontSize: "16px",
                        lineHeight: "20px",
                        padding: "5px 10px",
                    },
                },
            },
            {
                props: { variant: 'browse_blue' },
                style: {
                    background: "linear-gradient(90deg, #4AB5FC 0%, #405CFB 100%)",
                    color: "#ffffff",
                    maxWidth: "300px",
                    width: "100%",
                    height: "72px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: '26px',
                    lineHeight: "32px",
                    fontWeight: "600",
                    borderRadius: "10px",
                    textTransform: 'none',
                    letterSpacing: "0px",
                    transition: "all 2s",
                    "&:hover": {
                        background: "linear-gradient(270deg,rgba(74, 181, 252, 1) 0%, rgba(64, 92, 251, 1) 100% )",
                    },
                    '@media (max-width:1199px)': {
                        fontSize: "24px",
                        lineHeight: "30px",
                    },
                    '@media (max-width:899px)': {
                        fontSize: "20px",
                        lineHeight: "24px",
                        padding: "12px",

                    },
                    '@media (max-width:599px)': {
                        fontSize: "18px",
                        lineHeight: "22px",
                    },
                }
            },
            {
                props: { variant: 'apply_btn' },
                style: {
                    background: "#48A4FC",
                    color: "#ffffff",
                    padding: "11px 18px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: '18px',
                    lineHeight: "22px",
                    fontWeight: "400",
                    borderRadius: "8px",
                    textTransform: 'none',
                    letterSpacing: "0px",
                    transition: "all 2s",
                    width: "100%",
                    maxWidth: "96px",
                    height: "54px",
                    "&:hover": {
                        background: "#405CFB",
                    },
                    '@media (max-width:1199px)': {
                        fontSize: "16px",
                        lineHeight: "20px",
                        padding: "11px 22px",
                        height: "48px",
                    },
                    '@media (max-width:899px)': {
                        fontSize: "14px",
                        lineHeight: "18px",
                        padding: "10px 18px",
                        height: "42px",
                    },
                    '@media (max-width:599px)': {
                        padding: "9px 16px",
                        // height: "42px",
                    },
                }
            },
            {
                props: { variant: 'checkout_btn' },
                style: {
                    background: "linear-gradient(90deg, #4AB5FC 0%, #405CFB 100%)",
                    color: "#ffffff",
                    width: "100%",
                    height: "50px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: '18px',
                    lineHeight: "22px",
                    // fontWeight: "600",
                    borderRadius: "10px",
                    textTransform: 'none',
                    letterSpacing: "0.36px",
                    transition: "all 2s",
                    "&:hover": {
                        background: "linear-gradient(270deg,rgba(74, 181, 252, 1) 0%, rgba(64, 92, 251, 1) 100% )",
                    },
                    '@media (max-width:1199px)': {
                        fontSize: "16px",
                        lineHeight: "20px",
                        padding: "16px 15px",
                        height: "42px",
                    },
                    '@media (max-width:899px)': {
                        fontSize: "14px",
                        lineHeight: "18px",
                        padding: "14px 12px",
                        height: "36px",
                    },
                    '@media (max-width:599px)': {
                        fontSize: "12px",
                        lineHeight: "16px",
                        height: "30px",
                    },
                }
            },
            {
                props: { variant: 'outline_btn' },
                style: {
                    border: "1px solid #405CFB",
                    color: "#405CFB",
                    width: "100%",
                    height: "50px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: '18px',
                    lineHeight: "22px",
                    // fontWeight: "600",
                    borderRadius: "10px",
                    textTransform: 'none',
                    letterSpacing: "0.36px",
                    transition: "all 0.8s",
                    "&:hover": {
                        background: "#405CFB",
                        color: "#ffffff",
                    },
                    '@media (max-width:1199px)': {
                        fontSize: "16px",
                        lineHeight: "20px",
                        // padding: "16px 15px",
                        height: "42px",

                    },
                    '@media (max-width:899px)': {
                        fontSize: "14px",
                        lineHeight: "18px",
                        padding: "14px 12px",
                        height: "36px",
                    },
                    '@media (max-width:599px)': {
                        fontSize: "12px",
                        lineHeight: "16px",
                        height: "30px",
                    },
                }
            },
            {
                props: { variant: 'subscribe_btn' },
                style: {
                    background: "linear-gradient(90deg, #4AB5FC 0%, #405CFB 100%)",
                    color: "#ffffff",
                    width: "100%",
                    height: "72px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: '18px',
                    lineHeight: "22px",
                    // fontWeight: "600",
                    borderRadius: "10px",
                    textTransform: 'none',
                    letterSpacing: "0.36px",
                    transition: "all 2s",
                    "&:hover": {
                        background: "linear-gradient(270deg,rgba(74, 181, 252, 1) 0%, rgba(64, 92, 251, 1) 100% )",
                    },
                    '@media (max-width:1199px)': {
                        fontSize: "16px",
                        lineHeight: "20px",
                        padding: "16px 15px",
                        height: "60px",
                    },
                    '@media (max-width:899px)': {
                        fontSize: "14px",
                        lineHeight: "18px",
                        padding: "14px 12px",
                        height: "48px",
                    },
                    '@media (max-width:599px)': {
                        fontSize: "12px",
                        lineHeight: "16px",
                        height: "40px",
                    },
                }
            },
            {
                props: { variant: 'test_outline_btn' },
                style: {
                    border: "1px solid transparent",
                    background: "#405CFB",
                    color: "#fff",
                    maxWidth: "99px",
                    width: "100%",
                    height: "45px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: '16px',
                    lineHeight: "26px",
                    fontWeight: "600",
                    borderRadius: "23px",
                    textTransform: 'none',
                    letterSpacing: "0px",
                    transition: "all 0.8s",
                    "&:hover": {
                        color: "#405CFB",
                        borderColor: "#405CFB",
                    },
                }
            },
        ]
    }
}

