export const URLS = {
  registerUser: 'User/registerUser',
  verifyUserWithOtp: 'User/verifyRegisterUserWithOtp',
  welcomeEmail: 'User/welcomeEmail',
  loginUser: 'User/login',
  forgotPassword: 'user/sendForgotPasswordOtp',
  confirmForgotPasswordOtp: 'user/confirmForgotPasswordOtp',
  forgotPasswordSave: 'user/forgotPasswordSave',
  globalSearch: 'ProductForm/globalSearch',
  getSliderData: 'Slider/search',
  getTestimonial: 'Testimonial/search',
  getCategoryList: 'Category/search',
  getCategoryName: 'Category/getByUrl',
  getCategoryDetail: 'CategoryLevel2/getByUrl',
  getProductDetail: 'CategoryLevel3/getByUrl',
  getCategory: 'CategoryLevel2/findByCategory',
  getCategory2: 'CategoryLevel3/findByCategoryLevel2',
  getCategory3: 'ProductForm/findByCategoryLevel3',
  getCartItem: `Cart/getMyCart`,
  addCartItem: 'Cart/save',
  removecartItem: 'Cart/save',
  getDiscount: 'Coupon/getDiscount',
  getMyOrder: 'Order/getMyOrder',
  getLowerCategories: 'CategoryLevel3/getLowerCategories',
  changeUserProfile: 'User/getOne',
  doChangePassword: 'User/dochangepassword',
  contactUsSave: 'ContactUs/save',
  contactUsEmail: 'ContactUs/emailContactUs',
  newsLetterSave: 'NewsLetter/save',
  getBillingAddress: 'Order/populateBillingAddress',
  findFreeTest: 'ProductForm/findFreeTest',
  UserloginGoogle: 'User/loginGoogle',
  userProfile: '/user/profile',
  UserloginFacebook: 'User/loginFacebook',
  setUser: 'User/setUser',
  resendOTP: 'User/resendOtp',
  // orderTokens: "Order/tokens",
  orderTokens: 'tokens',
  orderPayWithStripe: 'Order/payWithStripe',
  orderInvoiceViewUrl: 'Order/invoiceViewUrl',
  orderInvoiceMail: 'Order/invoiceMail',
  withoutPaymentOrder: 'Order/withoutPaymentOrder',
  deleteUser: 'User/delete',
};
