import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { IntlContext } from 'src/App';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@mui/styles';
import '../commonComp.scss';
import { URLS } from 'src/helpers/constants/url';
import { fetchFromStorage } from 'src/helpers/context/Storage';
import OmnibusBanner from '../UI/OmnibusBanner';
import { ReactComponent as CartIcon } from 'src/assets/images/icons/cart.svg';
import { ReactComponent as Rarrow } from 'src/assets/images/icons/arrow-right.svg';
import SandClock from '../UI/SandClock';
import CartDialogBox from '../UI/CartDialogBox';
import axiosInstance from 'src/helpers/context/axiosInstance';
import PreLoader from 'src/component/Loader';
import DialogBox from '../UI/DialogBox';
import LogIn from '../../Auth/Login/Login';
import SignUp from '../../Auth/Signup/Signup';
import { ReactComponent as ChevronRight } from 'src/assets/images/icons/chevron-right.svg';
import { ReactComponent as CirclePlusIcon } from 'src/assets/images/icons/bExpandIcon.svg';
import { ReactComponent as ACheckmark } from 'src/assets/images/icons/acheckmark.svg';
import Category_summery from './categorySummery';
import Category_details from './categoryDetails';
import { Config } from 'src/helpers/context/config';

const useStyles = makeStyles((theme) =>
  createStyles({
    root_a: {
      overflow: 'auto',
      '& .MuiPaper-root': {
        marginBottom: '10px',
        '@media (max-width:1199px)': {
          width: '1088px',
        },
        '@media (max-width:899px)': {
          width: '888px',
        },
        '@media (max-width:599px)': {
          width: '688px',
        },
        '&:before': {
          height: '0px',
        },
        '&.Mui-expanded': {
          marginBottom: '0px',
        },
      },
      '& .MuiAccordion-root': {},
      '& .MuiAccordionSummary-root': {
        backgroundColor: '#DEE9F1',
        borderRadius: '3px',
        flexDirection: 'row-reverse',
        padding: '0px',
        // minHeight: "28px",
        height: '54px',
        padding: '13px 18px',

        '& .MuiAccordionSummary-content': {
          margin: '0px 0',
          paddingLeft: '14px',
          display: 'block',
          '& .catLevel_name': {
            fontWeight: '500',
            lineHeight: '19px',
            '@media (max-width:1199px)': {
              fontSize: '18px',
              lineHeight: '24px',
            },
            '@media (max-width:899px)': {
              fontSize: '16px',
              lineHeight: '22px',
            },
            '@media (max-width:599px)': {
              fontSize: '14px',
              lineHeight: '20px',
            },
          },
          '& .price_chapter_details': {
            display: 'flex',
          },
          '& .add_chapter_price': {
            fontWeight: '500',
            marginRight: '27.2px',
          },
          '& .add_chapter': {
            fontWeight: '600',
            color: '#41A6E9',
          },
          '& .linkDeco': {
            textDecoration: 'underline',
            color: '#41A6E9',
            '& .r-arrow': {
              marginLeft: '5px',
              '& path': {
                stroke: '#41A6E9',
              },
            },
          },
          '&.Mui-expanded': {
            margin: '0',
            color: '#fff',
          },
        },
        '&.Mui-expanded': {
          '& svg': {
            '& #Line_77': {
              display: 'none',
            },
          },
        },
      },
      '& .Mui-expanded': {
        '& .MuiAccordionSummary-root': {
          backgroundColor: '#566876',
          '& .MuiAccordionSummary-content': {
            '& .catLevel_name': {
              color: '#fff',
            },
            '& .price_chapter_details': {
              display: 'flex',
            },
            '& .add_chapter_price': {
              color: '#fff',
            },
            '& .add_chapter': {
              color: '#fff',
            },
            '& .linkDeco': {
              color: '#fff',
              '& .cartIcon': {
                '& path': {
                  fill: '#fff',
                },
              },
              '& .r-arrow': {
                '& path': {
                  stroke: '#fff',
                },
              },
            },
          },
        },
      },
      '& .test_title': {
        padding: '27px 0 9px 38px',
        color: '#41A6E9',
        fontSize: '22px',
        lineHeight: '28px',
        fontWeight: '600',
        display: 'block',
      },
      '& .acc_content': {
        borderTop: '1px solid #E7E9EA',
        borderBottom: '1px solid #E7E9EA',
        margin: '0px 48px 36px 38px',
        padding: '13px 0',
        overflow: 'auto',
      },
      '& .MuiCollapse-root': {
        '&.MuiCollapse-entered': {
          // padding: "27px 0 36px",
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: '10px 0px 0px 15px',
        '& .course_block': {
          paddingBottom: '9px',
        },
        '& .test_description': {
          padding: '0 0 13px 25px',
          fontSize: '11.5px',
          lineHeight: '16px',
        },
        '& .price_chapter_details': {
          display: 'flex',
        },
        '& .add_chapter_price': {
          color: '#5D6A73',
          marginRight: '27.2px',
        },
        '& .add_chapter': {
          color: '#5D6A73',
        },
        '& .linkDeco': {
          color: '#5D6A73',
          textDecoration: 'underline',
          justifyContent: 'flex-end',
          '& .cartIcon': {
            '& path': {
              fill: '#5D6A73',
            },
          },
          '& .r-arrow': {
            marginLeft: '5px',
            '& path': {
              stroke: '#5D6A73',
            },
          },
        },
      },
    },
  })
);

const CategoryLevel2 = () => {
  const classes = useStyles();
  const location = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const redux_auth = useSelector((state) => state?.ActionReduce?.authdata);
  const auth = redux_auth ? redux_auth : fetchFromStorage('authdata');
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [cartPopup, setCartPopup] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [subscriptionPackage, setSubscriptionPackage] = useState([]);
  const [loader, setLoader] = useState(false);
  const [togglePopup, setTogglePopup] = useState(true);
  const [popup, setPopup] = useState(false);
  const [myOrder, setMyOrder] = useState([]);
  const [cartItem, setCartItem] = useState([]);
  const [cartChapterItem, setCartChapterItem] = useState([]);
  const [cartTestItem, setCartTestItem] = useState([]);
  const [productList, setProductList] = useState([]);
  const [purchaseOmnibusArray, setPurchaseOmnibusArray] = useState([]);
  const [purchaseChapterArray, setPurchaseChapterArray] = useState([]);
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel, chapterSlug) => (e, newExpanded) => {
    setProductList([]);
    getCategory3(chapterSlug);
    setExpanded(newExpanded ? panel : false);
  };

  const lastSegment = location?.pathname?.split('/').pop();
  const catlevel3 = location?.pathname?.split('/')[2];

  const getCategoryDetail = async () => {
    let payload = JSON.stringify({
      myslug: lastSegment,
    });
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getCategoryDetail,
        payload
      );
      if (status === 200) {
        setLoader(false);
        setSubscriptionPackage(data?.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const getCategory2 = async () => {
    let payload = JSON.stringify({
      myslug: lastSegment,
    });
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getCategory2,
        payload
      );
      if (status === 200) {
        setLoader(false);
        setCategoryList(data?.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const getCategory3 = async (chapterSlug) => {
    let payload = JSON.stringify({
      myslug: chapterSlug,
    });
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getCategory3,
        payload
      );
      if (status === 200) {
        setLoader(false);
        setProductList(data?.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    const targetSlug = window.location.hash.split('#')[1];
    if (targetSlug) {
      const getIndex = categoryList?.findIndex(
        (item) => item?.myslug === targetSlug
      );
      if (getIndex >= 0) {
        const targetPanel = `panel${getIndex.toString()}`;
        setProductList([]);
        getCategory3(targetSlug);
        setExpanded(targetPanel);
        const anchorEl = document.getElementById(targetSlug);
        anchorEl?.scrollIntoView();
      }
    }
  }, [categoryList?.length > 0]);

  const getCartItem = async (val) => {
    let payload = {
      orderId: null,
      productForm: [],
      user: auth?.data?._id,
    };
    var CurrentDate = moment().format('YYYY-MM-DD  HH:mm:ss ZZ');
    var ExpireDate = moment().add(1, 'years').format('YYYY-MM-DD  HH:mm:ss ZZ');
    let catData = {
      categoryLevel2: { ...subscriptionPackage, alreadyBuy: false },
      packageName: [subscriptionPackage?.name, catlevel3 && catlevel3],
      orderDate: CurrentDate,
      expiryDate: ExpireDate,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getCartItem,
        payload
      );
      if (status === 200 && data?.value === true) {
        setLoader(false);
        if (val === true) {
          setLoader(false);
          let categoryLevel2 = data?.data?.productForm?.map((obj) => {
            return obj?.categoryLevel2?._id;
          });
          let categoryLevel3 = data?.data?.productForm?.map((obj) => {
            return obj?.categoryLevel3?._id;
          });
          let test = data?.data?.productForm?.map((obj) => {
            return obj?.productForm?._id;
          });
          setCartItem(categoryLevel2);
          setCartChapterItem(categoryLevel3);
          setCartTestItem(test);
        } else {
          setLoader(false);
          data?.data?.productForm?.push(catData);
          addCartItemData(data?.data);
        }
      } else if (
        status === 200 &&
        data?.value === false &&
        data?.data?.productForm !== undefined
      ) {
        getCartItem();
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const addCartItemData = async (payload) => {
    try {
      const { data, status } = await axiosInstance.post(
        URLS.addCartItem,
        payload
      );
      if (status === 200) {
        let categoryLevel2 = payload?.productForm?.map((obj) => {
          return obj?.categoryLevel2?._id;
        });
        let categoryLevel3 = payload?.productForm?.map((obj) => {
          return obj?.categoryLevel3?._id;
        });
        let test = data?.data?.productForm?.map((obj) => {
          return obj?.productForm?._id;
        });
        setCartItem(categoryLevel2);
        setCartChapterItem(categoryLevel3);
        setCartTestItem(test);
        openPopup();
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const getMyOrder = async () => {
    let payload = {
      myslug: lastSegment,
      user: auth?.data?._id,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getMyOrder,
        payload
      );
      if (status === 200) {
        setLoader(false);
        var orderArray = data?.data;
        var omnibucIdArray = [];
        orderArray &&
          orderArray?.map((item) => {
            item?.productForm &&
              item?.productForm?.map((subitem) => {
                subitem?.categoryLevel2?._id &&
                  omnibucIdArray.push(subitem?.categoryLevel2?._id);
              });
          });
        setPurchaseOmnibusArray(omnibucIdArray);
        var categoryIdArray = [];
        orderArray &&
          orderArray?.map((item) => {
            item?.productForm &&
              item?.productForm?.map((subitem) => {
                subitem?.categoryLevel3?._id &&
                  categoryIdArray.push(subitem?.categoryLevel3?._id);
              });
          });
        setPurchaseChapterArray(categoryIdArray);
        var orderIdArray = [];
        orderArray &&
          orderArray?.map((item) => {
            item?.productForm &&
              item?.productForm?.map((subitem) => {
                subitem?.productForm?._id &&
                  orderIdArray.push(subitem?.productForm?._id);
              });
          });
        setMyOrder(orderIdArray);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getCategoryDetail();
    getMyOrder();
    getCartItem(true);
    getCategory2();
  }, []);

  const openPopup = () => {
    setCartPopup(true);
  };

  const loginPopup = () => {
    setPopup(true);
  };

  const OpenLoginPopup = () => {
    setPopup(false);
    setTimeout(() => {
      setTogglePopup(true);
    }, 200);
  };

  const sandclockcontent = () => {
    return (
      <>
        <Box sx={{ marginLeft: '20px', whiteSpace: { lg: 'nowrap' } }}>
          <Typography variant='body2' className='sub_content '>
            {gothe_mile?.sandclockcontent?.access}
          </Typography>
          <Typography variant='body2' className='sub_content'>
            {/* {gothe_mile?.sandclockcontent?.validation} */}
            Subscription valid for <b>One Year</b> or a maximum of{' '}
            <b>3 attempts</b> for each test, whichever occurs earlier
          </Typography>
        </Box>
      </>
    );
  };

  const AddToCartContent = () => {
    return (
      <>
        <Box>
          <Box
            className='flex_content checkmarkIcon'
            sx={{ justifyContent: 'flex-start' }}
          >
            <ACheckmark />
            <Typography className='addcart_text' sx={{ ml: '25px' }}>
              {gothe_mile?.cart?.testAdded}
            </Typography>
          </Box>
          <Typography
            className='addcart_title'
            sx={{ textTransform: 'uppercase' }}
          >
            {catlevel3}
            <span style={{ textTransform: 'capitalize' }}>
              {'-' + subscriptionPackage?.name}
            </span>
          </Typography>
          <Typography variant='subtitle2' className='test_cost'>
            {subscriptionPackage?.price &&
              'Price: $' + subscriptionPackage?.price}
          </Typography>
          <Grid container spacing={{ xs: 2, ms: 3 }}>
            <Grid item xs={12} md={6}>
              <Button variant='checkout_btn' href='/cart'>
                {gothe_mile?.cart?.cart_checkout}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button variant='outline_btn' onClick={() => setCartPopup(false)}>
                {gothe_mile?.cart?.continueShopping}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  var urlValue = location?.pathname?.split('/');
  var cat3Val = urlValue[2];
  var slug3 = state?.catlevel3?.slug ? state?.catlevel3?.slug : cat3Val;

  var backImg = {
    backgroundImage: `url(${
      Config?.mediaURL + '=' + subscriptionPackage?.imageDetailView
    })`,
  };

  return (
    <>
      {loader && <PreLoader />}
      {togglePopup ? (
        <DialogBox
          open={popup}
          handleClose={OpenLoginPopup}
          title={<Box style={{ paddingTop: '58px' }}>{gothe_mile?.logIn}</Box>}
          content={
            <LogIn
              togglePopup={togglePopup}
              setTogglePopup={setTogglePopup}
              popup={popup}
              setPopup={setPopup}
            />
          }
        />
      ) : (
        <DialogBox
          open={popup}
          handleClose={OpenLoginPopup}
          title={gothe_mile?.signUp}
          content={
            <SignUp
              setTogglePopup={setTogglePopup}
              togglePopup={togglePopup}
              setPopup={setPopup}
            />
          }
        />
      )}

      <Box className='edic_banner' sx={backImg}>
        <Box className='sub_container'>
          <Grid
            container
            spacing={4.3}
            className='flex_content'
            sx={{ justifyContent: { sm: 'center', lg: 'space-between' } }}
          >
            <Grid item xs={12} md={6} lg={7.85}>
              <Breadcrumbs
                separator='›'
                aria-label='breadcrumb'
                className='breadcrumb-wrap'
              >
                <Link underline='hover' color='#fff' href='/'>
                  {gothe_mile?.home?.home_text}
                </Link>
                <Link
                  underline='hover'
                  color='#fff'
                  href={'/category/' + slug3}
                >
                  {state?.catlevel3?.name
                    ? state?.catlevel3?.name
                    : cat3Val.replaceAll('-', ' ')}
                </Link>
                <Typography>{subscriptionPackage?.name}</Typography>
              </Breadcrumbs>
              <OmnibusBanner
                col='7.85'
                xs_col='12'
                color='#fff'
                title={subscriptionPackage?.name}
                content={
                  cartItem?.includes(subscriptionPackage?._id) ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pt: { xs: '22px', sm: '30px', lg: '36px' },
                        justifyContent: { xs: 'center', md: 'start' },
                      }}
                    >
                      <Button
                        variant='blue'
                        className='add_cart_btn'
                        endIcon={<Rarrow />}
                        onClick={() => navigate('/cart')}
                      >
                        {gothe_mile?.alreadyIncart}
                      </Button>
                    </Box>
                  ) : purchaseOmnibusArray?.includes(
                      subscriptionPackage?._id
                    ) ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pt: { xs: '22px', sm: '30px', lg: '36px' },
                      }}
                    >
                      <Button
                        variant='blue'
                        className='add_cart_btn view_btn'
                        endIcon={<Rarrow />}
                        onClick={() => navigate('/dashboard')}
                      >
                        {gothe_mile?.view_my_test}
                      </Button>
                      <Typography variant='h6' className='sub_omnibus'>
                        {gothe_mile?.you_subscribed_Omnibus}
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pt: { xs: '22px', sm: '30px', lg: '36px' },
                        justifyContent: { sm: 'center', lg: 'flex-start' },
                      }}
                    >
                      <Typography variant='subtitle2' className='au_cost'>
                        {subscriptionPackage?.price &&
                          'AU $' + subscriptionPackage?.price}
                      </Typography>
                      <Button
                        variant='blue'
                        className='add_cart_btn'
                        startIcon={<CartIcon />}
                        onClick={() => (auth ? getCartItem() : loginPopup())}
                      >
                        {gothe_mile?.add_to_cart}
                      </Button>
                    </Box>
                  )
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <SandClock content={sandclockcontent()} />
      <Box sx={{ p: { xs: '30px 0', md: '45px 0' }, background: '#F5F8FA' }}>
        <Box className='sub_container'>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ChevronRight />
            <Typography sx={{ ml: '16px' }} className='about_title'>
              {gothe_mile?.aboutCourse?.title}
            </Typography>
          </Box>
          <Typography
            sx={{ lineHeight: '19px', fontWeight: '500', p: '27px 0' }}
          >
            {gothe_mile?.aboutCourse?.description}
          </Typography>
          <Typography
            sx={{ lineHeight: '19px', fontWeight: '500', pb: '19px' }}
          >
            {gothe_mile?.aboutCourse?.includes}:
          </Typography>
          <Box
            className='about_course'
            dangerouslySetInnerHTML={{
              __html: subscriptionPackage?.description,
            }}
          />
        </Box>
      </Box>

      <Box className='sub_container'>
        <Box sx={{ m: { xs: '40px 0', sm: '40px 0 50px', lg: '45px 0 56px' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', pb: '36px' }}>
            <ChevronRight />
            <Typography sx={{ ml: '16px' }} className='about_title'>
              {gothe_mile?.category2?.chapters}
            </Typography>
          </Box>
          <Box className={classes.root_a}>
            {categoryList?.map((item, id) => {
              return (
                <>
                  <Accordion
                    key={id}
                    id={item?.myslug}
                    expanded={expanded === `panel${id}`}
                    disableGutters
                    elevation={0}
                    onChange={handleChange(`panel${id}`, `${item?.myslug}`)}
                  >
                    <AccordionSummary
                      aria-controls={`panel${id}d-content`}
                      id={`panel${id}d-header`}
                      expandIcon={<CirclePlusIcon />}
                    >
                      <Category_summery
                        auth={auth}
                        loginPopup={loginPopup}
                        categoryLevel2={item}
                        cartItem={cartItem}
                        cartChapterItem={cartChapterItem}
                        setCartChapterItem={setCartChapterItem}
                        subscriptionPackage={subscriptionPackage}
                        purchaseOmnibusArray={purchaseOmnibusArray}
                        purchaseChapterArray={purchaseChapterArray}
                      />
                    </AccordionSummary>
                    <Typography className='test_title'>
                      {gothe_mile?.category2?.tests}
                    </Typography>
                    <Box className='acc_content'>
                      {productList?.length > 0 ? (
                        productList?.map((list, index) => {
                          return (
                            <>
                              <AccordionDetails key={index} id={list?.myslug}>
                                <Category_details
                                  auth={auth}
                                  test={list}
                                  loginPopup={loginPopup}
                                  categoryLevel2={item}
                                  cartItem={cartItem}
                                  cartChapterItem={cartChapterItem}
                                  cartTestItem={cartTestItem}
                                  setCartTestItem={setCartTestItem}
                                  subscriptionPackage={subscriptionPackage}
                                  purchaseOmnibusArray={purchaseOmnibusArray}
                                  purchaseChapterArray={purchaseChapterArray}
                                  myOrder={myOrder}
                                />
                              </AccordionDetails>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <Box sx={{ textAlign: 'center' }}>
                            <Typography sx={{ fontWeight: '600' }}>
                              {gothe_mile?.noTestInchapter}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Accordion>
                </>
              );
            })}
          </Box>
        </Box>
      </Box>

      <CartDialogBox
        open={cartPopup}
        handleClose={() => setCartPopup(false)}
        content={<AddToCartContent />}
      />
    </>
  );
};
export default CategoryLevel2;
