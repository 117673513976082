import React from "react";
import { Dialog, Grow, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "666px",
        color: "#373737",
        padding: "27px",
        borderRadius: "5px",
        backgroundColor: "#F2F2F2",
        "@media (max-width:991px)": {
          padding: "40px 30px",
          maxWidth: "580px",
        },
        "@media (max-width:600px)": {
          padding: "20px 15px",
          margin: "15px",
          maxWidth: "330px",
        },
        "& .dialog_box_title": {
          width: "100%",
          fontSize: "50px",
          fontWeight: "700",
          lineHeight: "61px",
          letterSpacing: "0",
          padding: "0 0 27px 0",
          "@media (max-width:991px)": {
            fontSize: "40px",
            paddingBottom: "30px",
          },
          "@media (max-width:600px)": {
            fontSize: "28px",
            paddingBottom: "20px",
          },
        },
        "& .MuiDialogContent-root": {
          padding: "0px",
        },
      },
    },
  })
);

const TestResultDialogBox = ({ open, handleClose, content }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        className={classes.root}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
        scroll={"body"}
      >
        <DialogContent>{content}</DialogContent>
      </Dialog>
    </>
  );
};
export default TestResultDialogBox;
