import React from "react";
import Page from "../../component/Page";
import Search from "../component/Search/Search";

const Searchpage = () => {
    return (
        <>
            <Page title="Go the Exter Mile | Search">
                <Search />
            </Page>
        </>
    )
}
export default Searchpage;