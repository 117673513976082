import React, { useState } from "react";
import { Box, Typography, Tabs, Tab, Grid } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => createStyles({
    root: {
        marginTop: "59px",
        display: "flex",
        "@media (max-width:1199px)": {
            marginTop: "55px",
        },
        "@media (max-width:899px)": {
            marginTop: "40px",
        },
        // "@media (max-width:599px)": {
        //     marginTop: "40px",
        // },
        "& .MuiTabs-root": {
            "@media (max-width:899px)": {
            }
        },
        "& .MuiTab-root": {
            fontFamily: "'Montserrat', sans-serif",
            minHeight: "54px",
            maxWidth: "100%",
            textAlign: "left",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "0px 16px 12px 32px",
            "@media (max-width:1199px)": {
                padding: "0px 16px 12px 28px",
                minHeight: "46px",
            },
            "@media (max-width:899px)": {
                minHeight: "40px",
            },
            "& .MuiTypography-root": {
                fontSize: "26px",
                fontWeight: "600",
                lineHeight: "26px",
                color: "#707070",
                textTransform: "capitalize",
                letterSpacing: "0px",
                "@media (max-width:1199px)": {
                    fontSize: "24px",
                    lineHeight: "24px",
                },
                "@media (max-width:899px)": {
                    fontSize: "22px",
                    lineHeight: "22px",
                },
                "@media (max-width:599px)": {
                    fontSize: "20px",
                    lineHeight: "20px",
                }
            },
            "& .tab_subcontent": {
                display: "block",
                fontSize: "16px",
                fontWeight: "400",
                position: "absolute",
                bottom: "-4px",
                textTransform: "none",
                "@media (max-width:1199px)": {
                    fontSize: "14px",
                    lineHeight: "24px",
                },
                "@media (max-width:899px)": {
                    fontSize: "12px",
                    lineHeight: "22px",
                },
                "@media (max-width:599px)": {
                    fontSize: "12px",
                    lineHeight: "20px",
                }
            },
            "&:not(:last-child)": {
                marginBottom: "36px",
                "@media (max-width:1199px)": {
                    marginBottom: "30px",
                },
                "@media (max-width:899px)": {
                    marginBottom: "25px",
                },
                "@media (max-width:599px)": {
                    marginBottom: "20px",
                }
            },
            "&.Mui-selected": {
                "& .MuiTypography-root": {
                    color: "#373737",
                },
            },
        },
        "& .MuiTabs-indicator": {
            background: "linear-gradient(180deg , rgba(74,181,252,1) 0%, rgba(64,92,251,1) 100%)",
            zIndex: "-1",
            color: "#373737",
            width: "12px",
            left: 0
        },
    }
}))

const TabsComponent = ({ tabs }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (<>
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>{children}</Box>
                )}
            </div>

        </>)
    };

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    return (<>
        <Box className={classes.root}>
            <Grid container spacing={{ xs: 3, sm: 5, lg: 13 }} >
                <Grid item xs={10} sm={6} md={4.3}>
                    <Tabs
                        orientation="vertical"
                        value={value}
                        onChange={handleChange}
                        aria-label="icon position tabs example"
                    >
                        {tabs?.map((item, i) => {
                            return (
                                <Tab key={i} icon={item?.icon} iconPosition="start" label={<Typography>{item?.label}<span className="tab_subcontent">{item?.subcontent}</span></Typography>} {...a11yProps(i)} />
                            )
                        })}
                    </Tabs>
                </Grid>
                <Grid item xs={12} sm={10} md={7.7}>
                    {tabs?.map((item, i) => {
                        return (
                            <TabPanel key={i} value={value} index={i}>
                                {item?.content}
                            </TabPanel>
                        )
                    })}
                </Grid>
            </Grid>
        </Box>
    </>)
}
export default TabsComponent;