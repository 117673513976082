import React, { useContext } from "react";
import { IntlContext } from "src/App";
import { Button, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  removeFromStorage,
  fetchFromStorage,
} from "src/helpers/context/Storage";
import axiosInstance from "src/helpers/context/axiosInstance";
import userDetails from "src/helpers/store/Actions/userDetails";
import { URLS } from "src/helpers/constants/url";

const DeactiveAccount = ({ close }) => {
  const intlContext = useContext(IntlContext);
  const navigate = useNavigate();
  const gothe_mile = intlContext?.messages;

  const redux_auth = useSelector((state) => state?.ActionReducer?.authdata);
  const auth = redux_auth ? redux_auth : fetchFromStorage("authdata");

  const handleDelete = async () => {
    const payload = {
      _id: auth?.data?._id,
    };

    try {
      const { data, status } = await axiosInstance.post(
        URLS?.deleteUser,
        payload
      );
      if (status === 200) {
        removeFromStorage("authdata");
        window.location = "/";
      }
    } catch (error) {}
  };

  return (
    <>
      <h1>{gothe_mile?.deleteAccount?.account_title}</h1>
      <p>{gothe_mile?.deleteAccount?.account_content}</p>
      <Box
        sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        className="btn-wrap"
      >
        <Button className="cancle" onClick={close}>
          {gothe_mile?.cancel}
        </Button>
        <Button className="approve" onClick={handleDelete}>
          {gothe_mile?.deleteAccount?.deactive_account}
        </Button>
      </Box>
    </>
  );
};

export default DeactiveAccount;
