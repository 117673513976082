import React from "react";
import { Box, Typography, Avatar, Link, } from "@mui/material";
import { ReactComponent as McqIcon } from "src/assets/images/icons/mcq.svg";
import { ReactComponent as Playstore } from "src/assets/images/icons/playstore.svg";
import { ReactComponent as Appstore } from "src/assets/images/icons/appstore.svg";
import AppMockup from "src/assets/images/icons/appmockup.svg";

const LinkToApp = ({ gothe_mile }) => {

    return (
        <>
            <Box className="our-app-wrap bg-light-blue sm-title">
                <Typography variant="h2" className="sub-title">{gothe_mile?.home?.download_app}</Typography>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: "15.9px 0px 39.7px 0px" }} >
                    <McqIcon />
                    <Typography variant="string">{gothe_mile?.home?.cccMcq}</Typography>
                </Box>
                <Box sx={{ justifyContent: "center" }} className="dw-app flex_content">
                    <Link target="_blank" href="https://play.google.com/store/apps/details?id=com.gtem.app&hl=en&gl=US">
                        <Playstore style={{ width: "230.44px" }} />
                    </Link>
                    <Link target="_blank" href="https://apps.apple.com/in/app/critical-care-mcq/id1576829548">
                        <Appstore style={{ width: "207.1px" }} />
                    </Link>
                </Box>
                <Box className="app-img">
                    <Avatar src={AppMockup} alt="Mobile-App" />
                </Box>
            </Box>
        </>
    )
}
export default React.memo(LinkToApp);