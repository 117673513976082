import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

export const InputTextField = withStyles({
  root: {
    "& label": {
      color: "#D4D4D4",
    },
    // "& .MuiOutlinedInput-root": {
    //   backgroundColor: "#16181B",
    //   border: "1px solid #25282C",
    // },
    "& label.Mui-focused": {
      color: "#D4D4D4",
    },
    // "& .MuiOutlinedInput-root.Mui-focused": {
    //   borderColor: "green",
    // },
    "& label:hover": {
      color: "#D4D4D4",
    },
    "& label.Mui-focused.Mui-error": {
      color: "#C01515",
    },
    "& .MuiOutlinedInput-root": {
      background: "#fff",
      border: "1px solid #D4D4D4",
      borderRadius: "8px",
      fontFamily: "'Montserrat', sans-serif",
      paddingLeft: "21px",
      color: "#707070",
      lineHeight: "19px",
      "& .MuiOutlinedInput-input": {
        padding: "13.5px",
        fontSize: "18px",
        "@media (max-width:899px)": {
          fontSize: "16px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          padding: "10px 15px",
        },
      },
      "&.Mui-focused fieldset": {
        border: 0,
        // outline:"none"
      },
      "&.Mui-disabled": {
        padding: "0",
        background: "#D4D4D4",
        borderRadius: "10px",
        height: "54px",
        fontSize: "18px",
        lineHeight: "22px",
        // color: "#373737",
        // - webkit - text - fill - color: $color- light - black,
        padding: "0",
        "@media (max-width:1199px)": {
          height: "48px",
        },
        "@media (max-width:899px)": {
          height: "40px",
        },
        "@media (max-width:599px)": {
          height: "36px",
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "'Montserrat',sans-serif",
      color: "#C01515",
      fontSize: "14px",
      fontWeight: 500,
      margin: "4px 14px 0",
      lineHeight: "19px",
      padding: "0px",
    },
    "& input.internal-autofill-selected": {
      backgroundColor: "none"
    },
  }
})(TextField);

export const InputSearchField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      background: "#fff",
      border: "1px solid #405cfb",
      borderRadius: "10px",
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: "500",
      padding: "1px 0 1px 21px",
      color: "#373737",
      lineHeight: "24px",
      "@media (max-width:1440px)": {
        padding: "1px 0 1px 15px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "13.5px",
        "@media (max-width:599px)": {
          padding: "10px 15px",
        },
      },
      "&.Mui-focused fieldset": {
        border: 0,
        // outline:"none"
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
})(TextField);