import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0",
        zIndex: "3",
        "& .loader_box": {
            display: "flex",
            position: "fixed",
            top: "calc(50% - 22px)",
            left: "calc(50% - 21px)",
        }
    },
}))

export default function PreLoader() {
    const classes = useStyles()
    return (
        <Box className={classes.root}>
            <Box className="loader_box">
                <CircularProgress />
            </Box>
        </Box>
    );
}
