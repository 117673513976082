import React, { useContext } from "react";
import { Box, Drawer, Link, List, ListItem, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/close.svg";
import { useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context/Storage";
import { IntlContext } from "src/App";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles(() => ({
  drawer: {
    width: "100%",
    backgroundColor: "#000!important",
  },
}));

const NavMenu = ({ openMobile, onMobileClose, nav_menu, OpenPopup, sub_menu, openLogoutPopup }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const redux_auth = useSelector((state) => state?.ActionReducer?.authdata);
  const auth = redux_auth ? redux_auth : fetchFromStorage("authdata");

  const handleClick = () => {
    onMobileClose();
  };

  const userLogOut = (item) => {
    navigate(item?.href, item?.name == "Log out" && openLogoutPopup());
    onMobileClose();
  };

  const userLogIn = () => {
    OpenPopup();
    onMobileClose();
  };


  const content = (
    <>
      <Box className="menu_bar">
        <Box className="close_icon">
          <CloseIcon onClick={onMobileClose} />
        </Box>
        <List sx={{ p: "20px 0" }}>
          {nav_menu?.map((item, id) => {
            return (
              <>
                <ListItem key={id} style={{ padding: "0", justifyContent: "center" }}>
                  {item?.label === "Blog" ? <Link target="_blank" className="nav_link" href={item?.href}>{item?.label}</Link>
                    :
                    <HashLink className="nav_link" onClick={() => handleClick()} smooth to={item?.href} >
                      {item?.label}
                    </HashLink>
                  }
                </ListItem>
              </>
            )
          })}
          {auth ?
            sub_menu?.map((item, id) => {
              return (<>
                <ListItem key={id} sx={{ p: "0", justifyContent: "center" }}>
                  <Button sx={{ p: "0" }}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => userLogOut(item)}>
                    <Link className="nav_link">{item?.name}</Link>
                  </Button>
                </ListItem>
              </>)
            })
            :
            <ListItem sx={{ p: "0", justifyContent: "center" }}>
              <Button sx={{ p: "0" }}
                disableElevation
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={userLogIn}>
                <Link className="nav_link" >
                  {gothe_mile?.logIn} / {gothe_mile?.signUp}
                </Link>
              </Button>
            </ListItem>
          }
        </List>
      </Box>
    </>
  );

  return (
    <>
      {/* <Hidden lgUp> */}
      <Box className="show-1279">
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};
export default NavMenu;
