import React from "react";
import Page from "../../component/Page";
import Category from "../component/Category/Category";

const CategoryPage = () => {
    return (
        <>
            <Page title="Go the Extra Mile | ICU Orientation">
                <Category />
            </Page>
        </>
    )
};

export default CategoryPage;