import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid, Typography, Button, FormHelperText } from '@mui/material';
import { IntlContext } from 'src/App';
import { InputTextField } from '../UI/textfield';
import { createStyles, makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MuiPhoneNumber from 'material-ui-phone-number';
import { fetchFromStorage } from 'src/helpers/context/Storage';
import axiosInstance from 'src/helpers/context/axiosInstance';
import { URLS } from 'src/helpers/constants/url';
import PreLoader from 'src/component/Loader';
import { ReactComponent as RightArrow } from 'src/assets/images/icons/right_a.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflow: 'auto',
      '& .field_block': {
        '& .title': {
          fontSize: '18px',
          lineHeight: '22px',
          color: '#101010',
        },
      },
      '& input::-webkit-input-placeholder': {
        color: '#707070',
        opacity: '1',
      },
      '& .MuiFormHelperText-root': {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '19px',
        color: '#101010',
        letterSpacing: '0px',
        fontFamily: "'Montserrat', sans-serif",
        margin: '0px',
        paddingBottom: '12px',
        position: 'relative',
        '@media (max-width:1199px)': {
          fontSize: '18px',
          lineHeight: '22px',
        },
        '@media (max-width:899px)': {
          fontSize: '16px',
          lineHeight: '20px',
        },
        '@media (max-width:599px)': {
          fontSize: '14px',
          lineHeight: '18px',
        },
        '&.Mui-error': {
          color: '#C01515',
          margin: '6px 0',
          fontSize: '14px',
          lineHeight: '1',
          textTransform: 'capitalize',
          padding: '0px',
        },
      },
      '& .MuiOutlinedInput-root': {
        fontSize: '18px',
        lineHeight: '22px',
        borderRadius: '10px',
        letterSpacing: '0px',
        paddingLeft: '0',
        '& .MuiOutlinedInput-input': {
          padding: '16px 12px!important',
          height: '22px',
          '@media (max-width:1199px)': {
            height: '16px',
          },
          '@media (max-width:899px)': {
            padding: '14px 12px!important',
            height: '14px',
          },
          '@media (max-width:599px)': {
            padding: '12px !important',
            height: '12px',
          },
        },
        '@media (max-width:1199px)': {
          fontSize: '16px',
          lineHeight: '20px',
        },
        '@media (max-width:899px)': {
          fontSize: '14px',
          lineHeight: '18px',
        },
        '@media (max-width:599px)': {
          fontSize: '12px',
          lineHeight: '16px',
        },
      },
      '& .phoneNumber': {
        width: '100%',
        '& .MuiInput-root': {
          color: ' #707070',
          border: '1px solid #D4D4D4',
          background: '#fff',
          padding: '7px 7px 7px 13px',
          borderRadius: '8px',
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },
          '&.Mui-disabled': {
            '& .MuiInputAdornment-root': {
              display: 'none',
            },
            border: 'none',
            background: '#F7F7F7',
          },
          '& .MuiIconButton-root': {
            padding: '0px',
            '& svg': {
              width: '28px',
              height: '28px',
            },
          },
        },
      },
    },
    root_c: {
      textAlign: 'center',
      marginTop: '45px',
      '@media (max-width:899px)': {
        marginTop: '40px',
      },
      '@media (max-width:599px)': {
        marginTop: '30px',
      },
      '& .continue_btn': {
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '0.36px',
        height: '54px',
        maxWidth: '197px',
        width: '100%',
        padding: '16px',
        '& svg': {
          '@media (max-width:899px)': {
            width: '18px',
            height: '18px',
          },
          '@media (max-width:599px)': {
            width: '16px',
            height: '16px',
          },
        },
        '@media (max-width:1199px)': {
          height: '50px',
        },
        '@media (max-width:899px)': {
          fontSize: '16px',
          lineHeight: '20px',
          height: '45px',
          padding: '14px',
        },
        '@media (max-width:599px)': {
          fontSize: '14px',
          lineHeight: '18px',
          height: '40px',
          padding: '12px',
        },
      },
    },
    dropdownClass: {
      '& .MuiPaper-root': {
        height: '250px',
        maxHeight: 'calc(100% - 200px)',
      },
      '& .MuiMenuItem-root': {
        fontSize: '16px',
      },
    },
  })
);

const Checkout = ({ onNextStep, expandNext, address, setAddress }) => {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const redux_auth = useSelector((state) => state?.ActionReducer?.authdata);
  const auth = redux_auth ? redux_auth : fetchFromStorage('authdata');
  const [contactData, setContactData] = useState({});
  const [loader, setLoader] = useState(false);

  const getBillingAddress = async () => {
    let payload = {
      email: auth?.data?.email,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS?.getBillingAddress,
        payload
      );
      if (status === 200) {
        setLoader(false);
        if (data?.value === true) {
          setLoader(false);
          setAddress(data?.data);
        } else {
          const adreessData = {
            address: {
              contryCode: auth?.data?.country_code,
            },
            contact: auth?.data?.mobile.trim(),
            contactNumber: auth?.data?.mobile.trim(),
          };
          setAddress(adreessData);
        }
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getBillingAddress();
  }, []);

  return (
    <>
      {loader && <PreLoader />}

      {/* USER DETAILS */}
      <Box className={classes.root}>
        <Box className='field_block'>
          <Typography variant='subtitle2' className='title'>
            {gothe_mile?.contactInformation}
          </Typography>
          <Grid container spacing={2.25} sx={{ pt: '18px' }}>
            <Grid item xs={12} md={6}>
              <FormHelperText>{gothe_mile?.firstName}</FormHelperText>
              <InputTextField
                disabled
                fullWidth
                className='disable_field'
                value={auth?.data?.firstName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormHelperText>{gothe_mile?.lastName}</FormHelperText>
              <InputTextField
                disabled
                fullWidth
                className='disable_field'
                value={auth?.data?.lastName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormHelperText>{gothe_mile?.email_address}</FormHelperText>
              <InputTextField
                disabled
                fullWidth
                className='disable_field'
                value={auth?.data?.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormHelperText>{gothe_mile?.mobile_number}</FormHelperText>
              <InputTextField
                disabled
                fullWidth
                className='disable_field'
                value={auth?.data?.mobile}
              />
            </Grid>
          </Grid>
        </Box>

        {/* BILLING ADDRESS */}

        <Box
          className='field_block'
          sx={{ mt: { xs: '22px', md: '34px', lg: '45px' } }}
        >
          <Typography variant='subtitle2' className='title'>
            {gothe_mile?.billing_address}
          </Typography>
          <Formik
            enableReinitialize={true}
            initialValues={{
              line1: address ? address?.address?.line1 : '',
              line2: address ? address?.address?.line2 : '',
              country: address ? address?.address?.country : '',
              state: address ? address?.address?.state : '',
              city: address ? address?.address?.city : '',
              contact: `+${address?.address?.contryCode}${address?.contact}`,
              postal_code: address ? address?.address?.postal_code : '',
            }}
            validate={(values) => {
              const valueArray = Object.values(values);
              const isEmpty = valueArray.includes('');
              onNextStep(!isEmpty);
            }}
            validationSchema={Yup.object().shape({
              line1: Yup.string()
                .trim()
                .nullable()
                .required(gothe_mile?.validation?.required_message),
              line2: Yup.string()
                .trim()
                .nullable()
                .required(gothe_mile?.validation?.required_message),
              country: Yup.string()
                .nullable()
                .required(gothe_mile?.validation?.required_message),
              state: Yup.string()
                .nullable()
                .required(gothe_mile?.validation?.required_message),
              city: Yup.string()
                .nullable()
                .required(gothe_mile?.validation?.required_message),
              contact: Yup.string()
                .trim()
                .nullable()
                .min(10, 'Phone number is not valid')
                .required(gothe_mile?.validation?.required_message),
              postal_code: Yup.string()
                .nullable()
                .required(gothe_mile?.validation?.required_message),
            })}
            onSubmit={(values) => {
              const userAdress = {
                city: values?.city,
                country: values?.country,
                line1: values?.line1,
                line2: values?.line2,
                postal_code: values?.postal_code,
                state: values?.state,
                contryCode: address?.address?.contryCode,
              };
              if (contactData && Object.keys(contactData).length !== 0) {
                const countryCode = contactData?.dialCode;
                const countryCodewithPlus = `+${countryCode}`;
                const phoneNumber = values?.contact
                  ?.split(countryCodewithPlus)
                  .pop();
                address['contact'] = phoneNumber.trim();
                address['contactNumber'] = phoneNumber.trim();
                userAdress['contryCode'] = countryCode;
              }
              address['address'] = userAdress;
              setAddress(address);
              expandNext('panel2');
            }}
          >
            {({
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2.25} sx={{ pt: '18px' }}>
                  <Grid item xs={12} md={6}>
                    <FormHelperText>{gothe_mile?.line_1}</FormHelperText>
                    <InputTextField
                      fullWidth
                      className='form_field'
                      type='text'
                      name='line1'
                      placeholder={gothe_mile?.line_1 + '*'}
                      error={Boolean(touched?.line1 && errors?.line1)}
                      helperText={touched?.line1 && errors?.line1}
                      value={values?.line1}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormHelperText>{gothe_mile?.line_2}</FormHelperText>
                    <InputTextField
                      fullWidth
                      className='form_field'
                      type='text'
                      name='line2'
                      placeholder={gothe_mile?.line_2 + '*'}
                      error={Boolean(touched?.line2 && errors?.line2)}
                      helperText={touched?.line2 && errors?.line2}
                      value={values?.line2}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2.25} sx={{ pt: '18px' }}>
                  <Grid item xs={12} md={6}>
                    <FormHelperText>{gothe_mile?.city}</FormHelperText>
                    <InputTextField
                      fullWidth
                      className='form_field'
                      type='text'
                      name='city'
                      placeholder={gothe_mile?.city + '*'}
                      error={Boolean(touched?.city && errors?.city)}
                      helperText={touched?.city && errors?.city}
                      value={values?.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormHelperText>{gothe_mile?.state}</FormHelperText>
                    <InputTextField
                      fullWidth
                      className='form_field'
                      type='text'
                      name='state'
                      placeholder={gothe_mile?.state + '*'}
                      error={Boolean(touched?.state && errors?.state)}
                      helperText={touched?.state && errors?.state}
                      value={values?.state}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2.25} sx={{ pt: '18px' }}>
                  <Grid item xs={12} md={6}>
                    <FormHelperText>{gothe_mile?.post_Code}</FormHelperText>
                    <InputTextField
                      fullWidth
                      className='form_field'
                      type='text'
                      name='postal_code'
                      placeholder={gothe_mile?.post_Code + '*'}
                      error={Boolean(
                        touched?.postal_code && errors?.postal_code
                      )}
                      helperText={touched?.postal_code && errors?.postal_code}
                      value={values?.postal_code}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ maxLength: 6 }}
                      onKeyPress={(e) => {
                        if (!/[a-zA-Z0-9\s]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormHelperText>{gothe_mile?.country}</FormHelperText>
                    <InputTextField
                      fullWidth
                      className='form_field'
                      type='text'
                      name='country'
                      placeholder={gothe_mile?.country + '*'}
                      error={Boolean(touched?.country && errors?.country)}
                      helperText={touched?.country && errors?.country}
                      value={values?.country}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2.25} sx={{ pt: '18px' }}>
                  <Grid item xs={12} md={6}>
                    <FormHelperText>{gothe_mile?.mobile_number}</FormHelperText>
                    <MuiPhoneNumber
                      fullWidth
                      enableLongNumbers={true}
                      defaultCountry='au'
                      className='phoneNumber'
                      placeholder={gothe_mile?.mobile_number + '*'}
                      dropdownClass={classes.dropdownClass}
                      name='contact'
                      disableAreaCodes={true}
                      error={Boolean(touched?.contact && errors?.contact)}
                      helperText={touched?.contact && errors?.contact}
                      value={values?.contact}
                      onChange={(value, data) => {
                        setFieldValue('contact', value);
                        setContactData(data);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Box className={classes.root_c}>
                  <Button type='submit' variant='blue' className='continue_btn'>
                    {' '}
                    {gothe_mile?.continue}
                    <RightArrow />
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};
export default Checkout;
