import React from "react";
import Page from "../../component/Page";
import FreeTest from "../component/FreeTests/FreeTests";

const FreeTestpage = () => {
    return (
        <>
            <Page title="Go the Extra Mile | TestFree">
                <FreeTest />
            </Page>
        </>
    )
}
export default FreeTestpage;