import React, { forwardRef, useEffect } from "react";
import { Helmet } from "react-helmet";

const Page = forwardRef(({ children, title = "", description = "", ...rest }, ref) => {

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [window.location.pathname]);

  return (
    <div ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </div>
  );
});

export default Page;
