import React from "react";
import Page from "../../component/Page";
import PrivacyPolicy from "../component/PrivacyPolicy/PrivacyPolicy";

const PrivacyPolicyPage = () => {
    return (
        <>
            <Page title="Go the Extra Mile | Privacy Policy">
                <PrivacyPolicy />
            </Page>
        </>
    )
}
export default PrivacyPolicyPage;