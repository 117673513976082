import React from "react";
import Page from "../../component/Page";
import UserProfile from "../component/UserProfile/userprofile";

const UserProfilePage = () => {
    return (<>
        <Page title="Go the Extra Mile | My Profile">
            <UserProfile />
        </Page>
    </>)
}
export default UserProfilePage;