import React from "react";
import { Dialog, Grow, DialogContent, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiPaper-root": {
        maxWidth: "894px",
        width: "100%",
        color: "#373737",
        // textAlign: "center",
        padding: "36px 36px 41px",
        overflowY: "auto",
        borderRadius: "20px",
        "@media (max-width:600px)": {
          padding: "30px 20px",
          margin: "15px",
        },
        "& .MuiDialogContent-root": {
          padding: "0px",
          // textAlign: "center",
        },
        // "& .close": {
        //   display: "flex",
        //   marginLeft: "auto",
        //   fill: "#405CFB",
        //   cursor: "pointer",
        //   width: "26px",
        //   height: "26px",
        // },
      },
    }
  }))

const CartDialogBox = ({ open, handleClose, title, content }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        className={classes.root}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
        scroll={"body"}
      >
        {/* <DialogTitle>{title}</DialogTitle> */}
        {/* <CloseIcon className="close" onClick={handleClose} /> */}
        <DialogContent>{content}</DialogContent>
      </Dialog>
    </>
  );
};
export default CartDialogBox;
