import React from "react";
import Page from "../../component/Page";
import TestForm from "../component/FreeTests/TestForm";

const TestFormPage = () => {
    return (
        <>
            <Page title="Go the Extra Mile | Test Page">
                <TestForm />
            </Page>
        </>
    )
}
export default TestFormPage;