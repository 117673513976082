import React, { useState } from 'react';
import { Box, Tabs, Tab, Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: '78px',
      display: 'flex',
      '@media (max-width:899px)': {
        marginTop: '58px',
      },
      '@media (max-width:599px)': {
        marginTop: '35px',
      },
      '& .MuiTabs-root': {
        '& .MuiTab-root': {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: '22px',
          fontWeight: '600',
          lineHeight: '26px',
          color: '#373737',
          textTransform: 'capitalize',
          letterSpacing: '0px',
          padding: '17px',
          minHeight: '71px',
          maxWidth: '100%',
          justifyContent: 'flex-start',
          paddingLeft: '90px',
          '&:not(:last-child)': {
            marginBottom: '28px',
          },
          '@media (max-width:1199px)': {
            paddingLeft: '70px',
            fontSize: '18px',
            lineHeight: '24px',
            minHeight: '60px',
            '&:not(:last-child)': {
              marginBottom: '20px',
            },
          },
          '@media (max-width:899px)': {
            // paddingLeft: "60px",
            fontSize: '16px',
            lineHeight: '22px',
            minHeight: '50px',
            '&:not(:last-child)': {
              marginBottom: '15px',
            },
          },
          '& svg.MuiTab-iconWrapper': {
            position: 'absolute',
            left: '25px',
            '@media (max-width:1199px)': {
              // left: "15px",
              width: '30px',
              height: '30px',
            },
            '@media (max-width:899px)': {
              width: '25px',
              height: '25px',
            },
          },
          '&.Mui-selected': {
            color: '#4AB5FC',
            '& svg.MuiTab-iconWrapper': {
              fill: '#4AB5FC',
            },
          },
          '&.deactiveAc': {
            color: '#CB2929',
            backgroundColor: '#fff',
            '& svg.MuiTab-iconWrapper': {
              fill: '#4AB5FC',
            },
          },
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: '#ECF8FF',
        zIndex: '-1',
        color: '#4AB5FC',
        width: '100%',
        borderRadius: '0 10px 10px 0',
      },
    },
  })
);

const TabsComponent = ({ tabs, handleChangeTab, value }) => {
  const classes = useStyles();
  // const [value, setValue] = useState(value);

  const handleChange = (e, newValue) => {
    // setValue(newValue);
    handleChangeTab(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <>
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && <Box>{children}</Box>}
        </div>
      </>
    );
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box className={classes.root}>
        <Grid
          container
          spacing={{ xs: 4, md: 7.37 }}
          sx={{ justifyContent: { xs: 'center' } }}
        >
          <Grid item xs={12} sm={6} md={4.76}>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="icon position tabs example"
            >
              {tabs?.map((item, i) => {
                return (
                  <Tab
                    key={i}
                    icon={item?.icon}
                    iconPosition="start"
                    label={item?.label}
                    {...a11yProps(i)}
                    className={item?.isDelete && 'deactiveAc'}
                  />
                );
              })}
            </Tabs>
          </Grid>

          <Grid item xs={12} sm={10} md={7.24}>
            {tabs?.map((item, i) => {
              return (
                <TabPanel key={i} value={value} index={i}>
                  {item?.content}
                </TabPanel>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default TabsComponent;
