import React from "react";
import Page from "../../component/Page";
import TermsConditions from "../component/Terms&Conditions/TermsConditions";

const TermsConditionsPage = () => {
    return (
        <>
            <Page title="Go the Extra Mile | Terms & Conditions">
                <TermsConditions />
            </Page>
        </>
    )
}
export default TermsConditionsPage;