import React from "react";
import { Box, Typography } from "@mui/material";
import "./ui.scss";
import SandClockIcon from "src/assets/images/icons/sand-clock.svg";
import { createStyles, makeStyles } from "@mui/styles";

const usestyles = makeStyles((theme) => createStyles({
    root: {
        "& .MuiTypography-root": {
            color: "#fff"
        }

    }
}))

const SandClock = ({ content }) => {
    const classes = usestyles();
    return (
        <>

            <Box className="sand_bg">
                <Box className="main_container">
                    <Box className={classes.root}>
                        <Box className="flex_content" style={{ padding: "20px 0" }}>
                            {/* <SandClockIcon /> */}
                            <img src={SandClockIcon} atl="SandClockIcon" />
                            {content}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>)
}
export default SandClock;