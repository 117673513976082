import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

const AccordionComp = ({ acc_data }) => {

    const [expanded, setExpanded] = useState('');

    const handleChange = (panel) => (e, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (<>
        {acc_data?.map((item, index) => {
            return (<>
                <Accordion key={index} expanded={expanded === `panel ${index + 1}`} disableGutters elevation={0} onChange={handleChange(`panel ${index + 1}`)}>
                    <AccordionSummary expandIcon={item?.icon} aria-controls="panel1d-content" id="panel1d-header">
                        {item?.summery}
                    </AccordionSummary>
                    <AccordionDetails>
                        {item?.content}
                    </AccordionDetails>
                </Accordion>
            </>)
        })}
    </>)
}
export default AccordionComp;